@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');



/* start: Globals */
*,
::before,
::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font: inherit;
}

body {
    font-family: 'Inter', sans-serif;
    color: var(--slate-700);
}

/* end: Globals */



/* start: Chat */
.chat-section {
    /* box-shadow: inset 0 160px 0 0 var(--emerald-500); */
    min-height: 50%;
    /* height: 100vh; */
    display: flex;
    align-items: center;
    /* justify-content: center; */
    background-color: #ffffff;
}

.chat-content-width {
    width: 100%;
}

.socialIcon {
    justify-content: center;
    gap: 5px;
    padding: 5px 0;
    border-bottom: 1px solid #E3E3E3;
    height: 65px;
}

.custom-icon {
    font-size: 20px;
    text-align: center;

}


.scroll-wrapper {
    /* padding-right: 10px; */
    float: right;
}



.premadechat-wrapper {
    /* display: flex; */
    /* justify-content: space-between; */
    white-space: nowrap;
    /* background: red; */
    /* overflow-x: scroll; */
    max-width: 850px;
    width: 100%;
    gap: 5px;

}

.test {
    /* position: absolute; */
    bottom: 0px;
    right: 0;
    /* margin: 10px; */
    width: 100%;
    /* padding: 10px; */
    background-color: white;
    bottom: 47px;
    overflow: scroll;
    height: 100px;
}

.premadechat-wid {
    /* width: 300px; */
    /* background-color: #d8d8d8 !important; */
    /* border: 4px solid #E9DDF4; */
    border: 4px solid rgb(127, 40, 248);
    background-color: transparent !important;
    box-shadow: none !important;
    /* color: #000; */
}

.new-wrapper-sugg {
    border: 1.5px solid rgb(127, 40, 248);
    background-color: transparent !important;
    box-shadow: none !important;
    color: #000 !important;
    font-weight: 400;


}

.new-wrapper-sugg:hover {
    font-weight: 500;
    background-color: rgb(127, 40, 248) !important;
    color: #ffffff !important;
}

.premadechat-wid:hover {
    /* background-color: #6565658c !important; */

    /* background-color: #E9DDF4 !important; */
    background-color: rgb(127, 40, 248) !important;
    transition: background-color 0.3s ease;
    /* color: rgb(40, 40, 40); */
    font-weight: 500;
    color: white !important;

}

.rem-MB {
    margin-bottom: 0px;
    overflow-wrap: break-word;
    width: 350px;
    word-wrap: break-word;
    white-space: normal;
    transition: background-color 0.3s ease;
}

/* .rem-MB:hover {
    color: #ffffff;
    background-color: rgb(127, 40, 248) !important;

} */
.spin-align{
    display: flex;
    align-items: center;
    justify-content: center;
}
.mb-none {
    margin-bottom: 0 !important;
    overflow: hidden;
    vertical-align: middle;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    cursor: pointer;
    word-break: break-word;
  }
  
  .mb-none.expanded {
    -webkit-line-clamp: unset;
    display: block;
    cursor: pointer;
  }
  .read-more{
    font-weight: 700;
    font-size: 13px;
    margin-right: 22px;
  }
  .read-less{
    font-weight: 700;
    font-size: 13px;
    margin-right: 22px;
  }
/* .mb-none {
    margin-bottom: 0 !important;
    overflow: hidden;
    vertical-align: middle;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
} */
.toggle-content-button {
    background-color: transparent; /* Blue background color */
    color: #777777; /* White text color */
    border: none; /* Remove border */
    cursor: pointer; /* Pointer cursor on hover */
    border-radius: 4px; /* Rounded corners */
    outline: none; /* Remove outline on focus */
    text-align: end;
  }
  

.premadechat-wrapper::-webkit-scrollbar {
    display: none;
}

.txt-clr{
    color: rgb(209, 209, 209) !important;
}

.icon_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: gray;
    transition: background-color 0.3s ease;
    padding: 2px 30px;
    border-radius: 5px;
}

.icon_wrapper:hover {
    background-color: #E3E3E3;
    color: #7f28f8;
}

.instagramclr {
    color: #C13584;
    transition: color 0.3s ease;
}

.icon_wrapper:hover .instagramclr {
    color: grey;
}

.icon_wrapper.active .instagramclr {
    color: var(--blue-300);
}

.chatsIcon-clr {
    /* color: grey; */
    transition: color 0.3s ease;

}

/* .icon_wrapper:hover .chatsIcon-clr {
    color: grey;
} */

.whatsapp-icon-sidebar {

    transition: color 0.3s ease;
}

.icon_wrapper:hover .whatsapp-icon-sidebar {
    color: #7f28f8;
}

.icon_wrapper.active .whatsapp-icon-sidebar {
    color: #7f28f8;
}

.icon_wrapper.active .chatsIcon-clr {
    color: #7f28f8;
}

.icon_wrapper.active .whatsapp-icon-sidebar {
    color: #7f28f8;
}

.isactive {
    color: #7f28f8 !important;
    background-color: #7f28f820;
}

.icontext-wrapper {
    font-size: 12px;
    margin-top: 5px;
    font-weight: 500;
    /* color: grey; */
}

/* .icontext-wrapper:hover{
    color: #7f28f8
} */

.insta {
    background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
    -webkit-background-clip: text;
    /* Also define standard property for compatibility */
    background-clip: text;
    -webkit-text-fill-color: transparent;

    /* font-size: 200px; */
}

.chat-container {
    /* max-width: 1360px; */
    width: 100%;
    height: 89vh;
    box-shadow: 0 8px 24px -4px rgba(0, 0, 0, .1);
    background-color: var(--white);
    position: relative;
}

/* end: Chat */



/* start: Sidebar */
.chat-sidebar {
    width: 64px;
    background-color: var(--slate-100);
    height: 100%;
    display: flex;
    flex-direction: column;
    /* position: absolute; */
    left: 0;
    top: 0;
    z-index: 50;
}

.chat-sidebar-logo {
    font-size: 28px;
    color: #7F28F8;
    display: block;
    text-align: center;
    padding: 12px 8px;
    text-decoration: none;
}

.chat-sidebar-menu {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 16px 0;
}

.chat-sidebar-menu>*>a {
    display: block;
    text-align: center;
    padding: 12px 8px;
    font-size: 24px;
    text-decoration: none;
    color: var(--slate-400);
    position: relative;
    transition: color .15s ease-in-out;
}

.chat-sidebar-menu>*>a:hover {
    color: var(--slate-600);
}

.chat-sidebar-menu>.active>a {
    box-shadow: inset 4px 0 0 0 #781eef;
    color: #781eef;
    background-color: #cdaafc;
}

.chat-sidebar-menu>*>a::before {
    content: attr(data-title);
    position: absolute;
    top: 50%;
    left: calc(100% - 16px);
    border-radius: 4px;
    transform: translateY(-50%);
    font-size: 13px;
    padding: 6px 12px;
    background-color: rgba(0, 0, 0, .6);
    color: var(--white);
    opacity: 0;
    visibility: hidden;
    transition: all .15s ease-in-out;
}

.chat-sidebar-menu>*>a:hover::before {
    left: calc(100% - 8px);
    opacity: 1;
    visibility: visible;
}

.chat-sidebar-profile {
    margin-top: auto;
    position: relative;
}

.chat-sidebar-profile-toggle {
    background-color: transparent;
    border: none;
    outline: transparent;
    width: 40px;
    height: 40px;
    margin: 0 auto;
    display: block;
    cursor: pointer;
}

.chat-sidebar-profile-toggle>img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.chat-sidebar-profile-dropdown {
    position: absolute;
    bottom: 100%;
    left: 16px;
    background-color: var(--white);
    box-shadow: 0 2px 8px rgba(0, 0, 0, .1);
    list-style-type: none;
    border-radius: 4px;
    padding: 4px 0;
    opacity: 0;
    visibility: hidden;
    transform: scale(.9);
    transform-origin: left bottom;
    transition: all .15s ease-in-out;
}

.chat-sidebar-profile.active .chat-sidebar-profile-dropdown {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
}

.chat-sidebar-profile-dropdown a {
    display: flex;
    align-items: center;
    padding: 8px 12px;
    text-decoration: none;
    color: var(--slate-400);
    font-size: 14px;
}

.chat-sidebar-profile-dropdown a:hover {
    background-color: var(--slate-100);
    color: var(--slate-600);
}

.chat-sidebar-profile-dropdown a:active {
    background-color: var(--slate-200);
}

.chat-sidebar-profile-dropdown a i {
    margin-right: 12px;
    font-size: 17px;
}

/* end: Sidebar */



/* start: Content side */
.chat-content {
    /* padding-left: 20px; */
    height: 100%;
    /* position: relative; */
}

.content-sidebar {
    width: 256px;
    background-color: var(--white);
    display: flex;
    flex-direction: column;
    height: 100%;
    position: absolute;
    border: 1px solid #e6ebf1;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    top: 0;
    /* left: 30px; */
}

.content-sidebar-title {
    font-size: 20px;
    font-weight: 600;
    padding: 0 16px;
}

.content-sidebar-form {
    position: relative;
    padding: 0 16px;
}

.content-sidebar-input {
    padding: 8px 16px;
    background-color: var(--slate-100);
    border: 1px solid var(--slate-300);
    outline: none;
    width: 100%;
    border-radius: 4px;
    padding-right: 32px;
    font-size: 14px;
}

.content-sidebar-input:focus {
    border-color: var(--slate-400);
}

.content-sidebar-submit {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 32px;
    color: var(--slate-400);
    background-color: transparent;
    outline: transparent;
    border: none;
    cursor: pointer;
    transition: color .15s ease-in-out;
}

.content-sidebar-submit:hover {
    color: var(--slate-600);
}

.content-messages {
    overflow-y: auto;

    /* height: 100%; */
    margin-top: 16px;

    height: 100%;
    /* height: 250px; */
    margin-top: 8px;
    height: calc(100vh - (65px * 3 + 16px * 6));
}

.whatsapp-icon {
    /* color: #48A04B; */
    color: #7f28f8;
}



.content-messages-list {
    list-style-type: none;
    padding: 10px 0;
    cursor: pointer;
}

.content-messages-list>*>a {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: var(--slate-700);
    padding: 6px 16px;
}

/* .content-messages-list>*>a:hover {
   background-color: #7f28f820; 
   padding: 10px 0;
}

.content-messages-list>.active>a {
    background-color: var(--slate-100);
} */

.content-messages-list li {
    margin-top: 5px;
    cursor: pointer;
    padding: 10px;
    transition: background-color 0.3s ease; /* Smooth transition for background color */
  }
  
  .content-messages-list li.active-chat,
  .content-messages-list li:hover {
    background-color: #7f28f820; /* Change this color as needed */
  }
.content-message-title {
    margin-left: 16px;
    margin-right: 16px;
    color: var(--slate-400);
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 2px;
    position: relative;
}

.content-message-title>* {
    position: relative;
    z-index: 1;
    padding: 0 4px 0 0;
    background-color: var(--white);
}

.content-message-title::before {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    width: 100%;
    height: 0;
    border-bottom: 1px solid var(--slate-300);
    z-index: 0;
}

.content-message-image {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    object-fit: cover;
    flex-shrink: 0;
    margin-right: 12px;
}

.content-message-info {
    display: grid;
    margin-right: 12px;
    width: 100%;
}

.content-message-name {
    display: block;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 2px;
}

.content-message-text {
    font-size: 13px;
    color: var(--slate-400);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.content-message-more {
    text-align: right;
}

.content-message-unread {
   
    color: var(--white);
    /* background-color: var(--emerald-500); */
    background-color: #7f28f8;
    padding: 6px;
    border-radius: 10px;
}

.content-message-time {
    font-size: 12px;
    color: var(--slate-400);
    font-weight: 500;
}

/* end: Content side */



/* start: Conversation */
.conversation {
    /* background-color: var(--slate-100); */
    height: 100%;
    padding-left: 256px;
    display: none;
    border-bottom-right-radius: 10px;
    border-radius: 10px;
}

.conversation.active {
    display: flex;
    flex-direction: column;


}

.conversation-top {
    padding: 4px 16px;
    background-color: var(--white);
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #e6ebf1;
    border-top-right-radius: 10px;
}

.conversation-back {
    background-color: transparent;
    border: none;
    outline: none;
    width: 32px;
    height: 32px;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    cursor: pointer;
    color: var(--slate-400);
    margin-right: 12px;
    display: none;

}

.conversation-back:hover {
    background-color: var(--slate-100);
    border-radius: 50%;
    color: var(--slate-600);
}

.conversation-back:active {
    background-color: var(--slate-200);
}

.conversation-user {
    /* display: flex;
    align-items: center; */
    padding: 20px;
    padding-left: 0;
}

.conversation-user-image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 8px;
}

.conversation-user-name {
    font-weight: 500;
    font-size: 17px;
    padding-bottom: 5px;
    /* padding: 20px; */
}

.conversation-user-status {
    color: var(--slate-400);
    font-size: 13px;
}

.conversation-user-status::before {
    content: '';
    width: 10px;
    height: 10px;
    background-color: var(--slate-300);
    border-radius: 50%;
    vertical-align: middle;
    display: inline-block;
    margin-right: 4px;
}

.conversation-user-status.online::before {
    background-color: var(--emerald-500);
}

.conversation-buttons {
    display: flex;
    align-items: center;
    margin-left: auto;
}

.conversation-buttons>* {
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    font-size: 20px;
    background-color: transparent;
    border: none;
    outline: transparent;
    cursor: pointer;
    color: var(--slate-600);
    margin-left: 4px;
}

.conversation-buttons> :hover {
    background-color: var(--slate-100);
    color: var(--slate-700);
}

.conversation-buttons> :active {
    background-color: var(--slate-200);
}

.conversation-main {
    overflow-y: auto;
    overflow-x: hidden;
    background-color: white;
    /* height: 95%; */
    height: calc(100% - 100px);
    padding: 16px;
}

.conversation-wrapper {
    list-style-type: none;
}

.conversation-item {
    display: flex;
    align-items: flex-end;
    flex-direction: row-reverse;
    margin-bottom: 16px;
    word-break: break-all;
}

.conversation-item.me {
    flex-direction: row;
    word-break: break-all;
}

.conversation-item-side {
    margin-left: 8px;
}

.conversation-item.me .conversation-item-side {
    margin-right: 8px;
}

.conversation-item-image {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    object-fit: cover;
    display: block;
}

.conversation-item-content {
    width: 100%;
}

.conversation-item-wrapper:not(:last-child) {
    margin-bottom: 8px;
}

/* .conversation-item-box {
    max-width: 200px;
    position: relative;
    margin-left: auto;
} */

.con-right{
    max-width: 350px;
    position: relative;
    margin-left: auto;
    min-width: 100px;
    width: auto;
    word-wrap: break-word;
    display: inline-block;
    float: right;
  
}

.con-left{
    max-width: 350px;
    position: relative;
    margin-left: auto;
    min-width: 100px;
    width: auto;
    word-wrap: break-word;
    display: inline-block;
    float: left;

}


.conversation-item.me .conversation-item-box {
    margin-left: unset;
}

.conversation-item-text {
    /* padding: 12px 16px 8px; */
    padding: 12px 16px 12px;
    box-shadow: 0 2px 12px -2px #E9DDF4;
    /* background-color: #E9DDF4; */

    background-color: rgb(127, 40, 248);
    color: #ffffff;
    /* color: black; */
    font-size: 14px;
    border-radius: 16px;
    line-height: 1.5;
    /* margin-left: 32px; */
}

.conversation-receiverMsg {
    border-bottom-left-radius: 4px !important;
    margin-bottom: 4px;

}


.conversation-senderMsg {
    border-bottom-right-radius: 4px !important;
    margin-bottom: 4px;
}

.conversation-item.me .conversation-item-text {
    margin-left: unset;
    margin-right: 32px;
}

.conversation-item.me .conversation-item-text {
    /* background-color: #7F28F8;
    box-shadow: 0 2px 12px -2px #7F28F8;
    color: rgba(255, 255, 255, .8); */

    color: black;

    background-color: var(--white);
    box-shadow: 0 2px 12px -2px rgba(0, 0, 0, .1);

}

.conversation-item-time {
    font-size: 10px;
    color: var(--slate-400);
    display: block;
    text-align: right;
    margin-top: 4px;
    line-height: 1;
}

.conversation-item.me .conversation-item-time {
    color: rgba(255, 255, 255, .7);
}

.conversation-item-dropdown {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
    transition: all .15s ease-in-out;
}

.conversation-item.me .conversation-item-dropdown {
    left: unset;
    right: 0;
}

.conversation-item-wrapper:hover .conversation-item-dropdown {
    opacity: 1;
    visibility: visible;
}

.conversation-item-dropdown-toggle {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    background-color: var(--white);
    outline: transparent;
    border: 1px solid var(--slate-200);
    cursor: pointer;
    transition: all .15s ease-in-out;
}

.conversation-item-dropdown-toggle:hover {
    background-color: var(--emerald-500);
    color: var(--white);
    box-shadow: 0 2px 12px -2px var(--emerald-500);
}

.conversation-item-dropdown-toggle:active {
    background-color: var(--emerald-600);
}

.conversation-item-dropdown-list {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: var(--white);
    z-index: 10;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .1);
    border-radius: 4px;
    padding: 4px 0;
    list-style-type: none;
    opacity: 0;
    visibility: hidden;
    transform: scale(.9);
    transform-origin: left top;
    transition: all .15s ease-in-out;
}

.conversation-item.me .conversation-item-dropdown-list {
    left: unset;
    right: 0;
}

.conversation-item-dropdown.active .conversation-item-dropdown-list {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
}

.conversation-item-dropdown-list a {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: var(--slate-400);
    font-size: 13px;
    padding: 6px 12px;
}

.conversation-item-dropdown-list a:hover {
    background-color: var(--slate-100);
    color: var(--slate-600);
}

.conversation-item-dropdown-list a:active {
    background-color: var(--slate-200);
}

.conversation-item-dropdown-list a i {
    font-size: 16px;
    margin-right: 8px;
}

.coversation-divider {
    text-align: center;
    font-size: 13px;
    color: var(--slate-400);
    margin-bottom: 16px;
    position: relative;
}

.coversation-divider::before {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    width: 100%;
    height: 0;
    border-bottom: 1px solid var(--slate-300);
}

.coversation-divider span {
    display: inline-block;
    padding: 0 8px;
    background-color: var(--slate-100);
    position: relative;
    z-index: 1;
}

.conversation-form {
    padding: 4px 16px;
    background-color: var(--white);
    display: flex;
    align-items: flex-end;
}

.conversation-form-group {
    width: 100%;
    position: relative;
    /* margin-left: 16px;
    margin-right: 16px; */

}

.conversation-form-input {
    background-color: white;
    border: 1px solid #e6ebf1;
    border-radius: 50px;
    outline: #e6ebf1;
    padding: 10px 32px 10px 16px;
    font: inherit;
    font-size: 14px;
    resize: none;
    width: 100%;
    display: block;
    line-height: 1.5;
    max-height: calc(20px + ((14px * 2) * 6));
}

.conversation-form-input:focus {
    border-color: #e6ebf1;
}

.conversation-form-record {
    position: absolute;
    bottom: 8px;
    right: 16px;
    font-size: 20px;
    color: black;
    background-color: white;
    border: none;
    outline: #e6ebf1;
    cursor: pointer;
}

.conversation-form-record:hover {
    color: var(--slate-600);
}

.conversation-form-button {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    border: none;
    background-color: transparent;
    outline: transparent;
    font-size: 20px;
    color: var(--slate-400);
    cursor: pointer;
    flex-shrink: 0;
}

.conversation-form-button:hover {
    background-color: var(--slate-100);
    color: var(--slate-600);
}

.conversation-form-button:active {
    background-color: var(--slate-200);
    color: var(--slate-600);
}

.conversation-form-submit {
    background-color: var(--emerald-500);
    box-shadow: 0 2px 8px -2px var(--emerald-500);
    color: var(--white);
}

.conversation-form-submit:hover {
    background-color: var(--emerald-600);
    color: var(--white);
}

.conversation-form-submit:active {
    background-color: var(--emerald-700);
    color: var(--white);
}

.conversation-default {
    align-items: center;
    justify-content: center;
    padding: 16px;
    padding-left: calc(256px + 16px);
    color: var(--slate-400);
    background-color: white;
}

.conversation-default i {
    font-size: 32px;
}

.conversation-default p {
    margin-top: 16px;
}

.chat-conversation-default {
    align-items: center;
    justify-content: center;
    padding: 16px;
    color: var(--slate-400);
    background-color: white;
}

.chat-conversation-default i {
    font-size: 32px;
}

.chat-conversation-default p {
    margin-top: 16px;
}

/* end: Conversation */

/* body.open_customer_info .chat-section {
    width: calc(100% - 376px);
} */

/* whatsapp Screen  */
.new-container {
    /* max-width: 1360px; */
    width: 100%;
    /* height: 89vh; */
    background-color: var(--white);
    position: relative;
    box-shadow: none !important;
}

.chatClr {
    background-color: #f6f6f6 !important;
}

.conversation_wapper {
    position: absolute;
    top: 12px;
    right: 2px;
    display: flex;
    gap: 10px;
    align-items: center;
    background-color: #F6F6F6;
}

.conversation-form-input_whatsapp {
    padding: 10px 32px 10px 32px !important;
    border-radius: 50px;
    background: #F6F6F6 !important;
}

.btn_imgs {
    cursor: pointer;
}

.cusWidth {
    width: 100%;
}

.screenWrapper {
    width: 100%;
    display: flex;
}

.column1 {
    width: 400px;
    /* box-shadow: 1px 10px #718096; */

}

.dropdown-wrapper {
    background: white;
    padding: 10px;
    text-align: center;
    font-size: 17px;
    font-weight: 600;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    margin-bottom: 10px;
    border-radius: 4px;

}

.custom_border-active {
    border-bottom: 1px solid #E3E3E3;
}

.custom_border:hover {
    background-color: #F4EAFF;
    border-left: 2px solid #48A04B;
}

.msgText {
    max-height: 1.2em;
    overflow: hidden;
    vertical-align: middle;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-all;
}

.dropdown-heading {
    color: #000;
}

.column2 {
    /* width: 70%; */
    transition: all 0.25s;
    display: flex;
    flex-direction: column;
    height: 100%;
    /* width: calc(100% - 400px); */
}

.Chatprofile {
    gap: 10px;
    align-items: center;
}

.chatheader {
    /* align-items: center;
    padding: 23px 20px;
    justify-content: space-between;
    box-shadow: 0 2px 1px -2px #718096;
    background-color: var(--white);
    border: 1px solid #718096;

 */


    padding: 23px 20px;
    background-color: var(--white);
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #e6ebf1;
    border-top-right-radius: 10px;
}

.iconCLr {
    color: #718096;
}

.filterImg {
    font-size: 14px;
    color: #B9B9B9;
}

.profile_name_text {
    margin-bottom: 0px;
    color: var(--black);
    font-weight: 500;
    font-size: 14px;
}

.profile-status {
    color: #48A04B;
    font-size: 12px;
    font-weight: 500;
}

.borderCus {
    border: 1px solid #EDF2F7 !important;
}

.menuBTns {
    gap: 10px;
    align-items: center;
}


.div-wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
    height: 65px;
}


/* msg css fixes */
.new-wrapper {
    display: flex;
    padding: 16px 16px 0;
    justify-content: space-between;
    align-items: center;
    height: 65px;
}

.Active-chat-text {
    font-size: 17px;
    color: #000000;
    font-weight: 600;
}

.Active-chat-no {
    color: #6B6B6B;
    font-size: 12.48px;
}

.clr_green {
    color: #48A04B;

}

.clr_green::before {
    content: "•";
    margin: 0 4px;
    color: #48A04B;
}

.circle-icon-btn {
    padding: 10px;
    background-color: #48A04B;
    border-radius: 50px;
    color: #ffffff;
    font-weight: 600;
}

.text_noChat {
    text-align: center;
    color: #000000;
    font-size: 25px;
}

.cus-gap-5 {
    gap: 5px;
    align-items: center;
}

.time-left {
    color: #A0AEC0;
    font-size: 12px;
    font-weight: 500;
}

.sender-name-text {
    color: #1A202C;
    font-size: 12.5px;
    font-weight: 500;

}

.time-right {
    color: #A0AEC0;
    font-size: 12px;
    font-weight: 500;
}

.currentTime {
    display: flex;
    justify-content: center;
    align-items: center;
}

.clr-white {
    background-color: #ffffff !important;
    color: #000;
}

.cus-mb-10 {
    margin-bottom: 10px;
}

.cusMarg-top {
    margin-top: 10px;
}

.new-input-wrapper {
    padding-top: 0px !important;
    padding: 20px;
    background-color: #ffffff;
    position: relative;
}

.whatsaap-input {
    padding-left: 43px;
}

.emoji-btn {
    position: absolute;
    left: 18px;
    top: 12px;
}

.emojiSvg {
    color: #9DB2CE;
    font-size: 20px;
}

.send-button {
    background-color: rgb(127, 40, 248);
    ;
    color: white;
    border: none;
    padding: 8px;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.3s ease;
    position: absolute;
    top: 6px;
    right: 30px;
}

.send-button:hover {
    background-color: rgb(127, 40, 248);
    ;
}

.send-button:active {
    background-color: rgb(127, 40, 248);
    ;
}

.send-button:focus {
    outline: none;
}

.emoji-picker-container {
    position: relative;
}

.gap-btw{
    gap: 10px;
}

.emoji-picker-popup {
    position: absolute;
    bottom: 100%;
    left: 0;
    margin-bottom: 10px;
    z-index: 1000;
    width: 100%;
}

.EmojiPickerReact {
    border-radius: 16px;
}

.EmojiPickerReact>div:nth-child(3) {
    display: none;
    background-color: red !important;
}
/* .cntrl_div{
    background-color: #7f28f820;
    height: 40px;

} */
.text_ctrl{
    padding: 10px;
    gap: 10px;
}

.whatApp_toggle{
        background-color: #7f28f820;
    height: 40px;
    
}
:root {
    /* This color palletes from Tailwind CSS */
    --white: #fff;
    --black: #000;

    --slate-50: #f8fafc;
    --slate-100: #f1f5f9;
    --slate-200: #e2e8f0;
    --slate-300: #cbd5e1;
    --slate-400: #94a3b8;
    --slate-500: #64748b;
    --slate-600: #475569;
    --slate-700: #334155;
    --slate-800: #1e293b;
    --slate-900: #0f172a;
    --slate-950: #020617;

    --gray-50: #f9fafb;
    --gray-100: #f3f4f6;
    --gray-200: #e5e7eb;
    --gray-300: #d1d5db;
    --gray-400: #9ca3af;
    --gray-500: #6b7280;
    --gray-600: #4b5563;
    --gray-700: #374151;
    --gray-800: #1f2937;
    --gray-900: #111827;
    --gray-950: #030712;

    --zinc-50: #fafafa;
    --zinc-100: #f4f4f5;
    --zinc-200: #e4e4e7;
    --zinc-300: #d4d4d8;
    --zinc-400: #a1a1aa;
    --zinc-500: #71717a;
    --zinc-600: #52525b;
    --zinc-700: #3f3f46;
    --zinc-800: #27272a;
    --zinc-900: #18181b;
    --zinc-950: #09090b;

    --neutral-50: #fafafa;
    --neutral-100: #f5f5f5;
    --neutral-200: #e5e5e5;
    --neutral-300: #d4d4d4;
    --neutral-400: #a3a3a3;
    --neutral-500: #737373;
    --neutral-600: #525252;
    --neutral-700: #404040;
    --neutral-800: #262626;
    --neutral-900: #171717;
    --neutral-950: #0a0a0a;

    --stone-50: #fafaf9;
    --stone-100: #f5f5f4;
    --stone-200: #e7e5e4;
    --stone-300: #d6d3d1;
    --stone-400: #a8a29e;
    --stone-500: #78716c;
    --stone-600: #57534e;
    --stone-700: #44403c;
    --stone-800: #292524;
    --stone-900: #1c1917;
    --stone-950: #0c0a09;

    --red-50: #fef2f2;
    --red-100: #fee2e2;
    --red-200: #fecaca;
    --red-300: #fca5a5;
    --red-400: #f87171;
    --red-500: #ef4444;
    --red-600: #dc2626;
    --red-700: #b91c1c;
    --red-800: #991b1b;
    --red-900: #7f1d1d;
    --red-950: #450a0a;

    --orange-50: #fff7ed;
    --orange-100: #ffedd5;
    --orange-200: #fed7aa;
    --orange-300: #fdba74;
    --orange-400: #fb923c;
    --orange-500: #f97316;
    --orange-600: #ea580c;
    --orange-700: #c2410c;
    --orange-800: #9a3412;
    --orange-900: #7c2d12;
    --orange-950: #431407;

    --amber-50: #fffbeb;
    --amber-100: #fef3c7;
    --amber-200: #fde68a;
    --amber-300: #fcd34d;
    --amber-400: #fbbf24;
    --amber-500: #f59e0b;
    --amber-600: #d97706;
    --amber-700: #b45309;
    --amber-800: #92400e;
    --amber-900: #78350f;
    --amber-950: #451a03;

    --yellow-50: #fefce8;
    --yellow-100: #fef9c3;
    --yellow-200: #fef08a;
    --yellow-300: #fde047;
    --yellow-400: #facc15;
    --yellow-500: #eab308;
    --yellow-600: #ca8a04;
    --yellow-700: #a16207;
    --yellow-800: #854d0e;
    --yellow-900: #713f12;
    --yellow-950: #422006;

    --lime-50: #f7fee7;
    --lime-100: #ecfccb;
    --lime-200: #d9f99d;
    --lime-300: #bef264;
    --lime-400: #a3e635;
    --lime-500: #84cc16;
    --lime-600: #65a30d;
    --lime-700: #4d7c0f;
    --lime-800: #3f6212;
    --lime-900: #365314;
    --lime-950: #1a2e05;

    --green-50: #f0fdf4;
    --green-100: #dcfce7;
    --green-200: #bbf7d0;
    --green-300: #86efac;
    --green-400: #4ade80;
    --green-500: #22c55e;
    --green-600: #16a34a;
    --green-700: #15803d;
    --green-800: #166534;
    --green-900: #14532d;
    --green-950: #052e16;

    --emerald-50: #ecfdf5;
    --emerald-100: #d1fae5;
    --emerald-200: #a7f3d0;
    --emerald-300: #6ee7b7;
    --emerald-400: #34d399;
    --emerald-500: #10b981;
    --emerald-600: #059669;
    --emerald-700: #047857;
    --emerald-800: #065f46;
    --emerald-900: #064e3b;
    --emerald-950: #022c22;

    --teal-50: #f0fdfa;
    --teal-100: #ccfbf1;
    --teal-200: #99f6e4;
    --teal-300: #5eead4;
    --teal-400: #2dd4bf;
    --teal-500: #14b8a6;
    --teal-600: #0d9488;
    --teal-700: #0f766e;
    --teal-800: #115e59;
    --teal-900: #134e4a;
    --teal-950: #042f2e;

    --cyan-50: #ecfeff;
    --cyan-100: #cffafe;
    --cyan-200: #a5f3fc;
    --cyan-300: #67e8f9;
    --cyan-400: #22d3ee;
    --cyan-500: #06b6d4;
    --cyan-600: #0891b2;
    --cyan-700: #0e7490;
    --cyan-800: #155e75;
    --cyan-900: #164e63;
    --cyan-950: #083344;

    --sky-50: #f0f9ff;
    --sky-100: #e0f2fe;
    --sky-200: #bae6fd;
    --sky-300: #7dd3fc;
    --sky-400: #38bdf8;
    --sky-500: #0ea5e9;
    --sky-600: #0284c7;
    --sky-700: #0369a1;
    --sky-800: #075985;
    --sky-900: #0c4a6e;
    --sky-950: #082f49;

    --blue-50: #eff6ff;
    --blue-100: #dbeafe;
    --blue-200: #bfdbfe;
    --blue-300: #93c5fd;
    --blue-400: #60a5fa;
    --blue-500: #3b82f6;
    --blue-600: #2563eb;
    --blue-700: #1d4ed8;
    --blue-800: #1e40af;
    --blue-900: #1e3a8a;
    --blue-950: #172554;

    --indigo-50: #eef2ff;
    --indigo-100: #e0e7ff;
    --indigo-200: #c7d2fe;
    --indigo-300: #a5b4fc;
    --indigo-400: #818cf8;
    --indigo-500: #6366f1;
    --indigo-600: #4f46e5;
    --indigo-700: #4338ca;
    --indigo-800: #3730a3;
    --indigo-900: #312e81;
    --indigo-950: #1e1b4b;

    --violet-50: #f5f3ff;
    --violet-100: #ede9fe;
    --violet-200: #ddd6fe;
    --violet-300: #c4b5fd;
    --violet-400: #a78bfa;
    --violet-500: #8b5cf6;
    --violet-600: #7c3aed;
    --violet-700: #6d28d9;
    --violet-800: #5b21b6;
    --violet-900: #4c1d95;
    --violet-950: #2e1065;

    --purple-50: #faf5ff;
    --purple-100: #f3e8ff;
    --purple-200: #e9d5ff;
    --purple-300: #d8b4fe;
    --purple-400: #c084fc;
    --purple-500: #a855f7;
    --purple-600: #9333ea;
    --purple-700: #7e22ce;
    --purple-800: #6b21a8;
    --purple-900: #581c87;
    --purple-950: #3b0764;

    --fuchsia-50: #fdf4ff;
    --fuchsia-100: #fae8ff;
    --fuchsia-200: #f5d0fe;
    --fuchsia-300: #f0abfc;
    --fuchsia-400: #e879f9;
    --fuchsia-500: #d946ef;
    --fuchsia-600: #c026d3;
    --fuchsia-700: #a21caf;
    --fuchsia-800: #86198f;
    --fuchsia-900: #701a75;
    --fuchsia-950: #4a044e;

    --pink-50: #fdf2f8;
    --pink-100: #fce7f3;
    --pink-200: #fbcfe8;
    --pink-300: #f9a8d4;
    --pink-400: #f472b6;
    --pink-500: #ec4899;
    --pink-600: #db2777;
    --pink-700: #be185d;
    --pink-800: #9d174d;
    --pink-900: #831843;
    --pink-950: #500724;

    --rose-50: #fff1f2;
    --rose-100: #ffe4e6;
    --rose-200: #fecdd3;
    --rose-300: #fda4af;
    --rose-400: #fb7185;
    --rose-500: #f43f5e;
    --rose-600: #e11d48;
    --rose-700: #be123c;
    --rose-800: #9f1239;
    --rose-900: #881337;
    --rose-950: #4c0519;
}


/* start: Breakpoints */
@media screen and (max-width: 1600px) {
    .chat-container {
        max-width: unset;
        margin-top: 20px;
        height: 89vh;
    }
}

@media screen and (max-width: 767px) {
    .chat-sidebar {
        top: unset;
        bottom: 0;
        width: 100%;
        height: 48px;
    }

    .chat-sidebar-logo {
        display: none;
    }

    .chat-sidebar-menu {
        flex-direction: row;
        padding: 0;
    }

    .chat-sidebar-menu>*,
    .chat-sidebar-menu>*>a {
        width: 100%;
        height: 100%;
    }

    .chat-sidebar-menu>*>a {
        padding: 8px;
    }

    .chat-sidebar-menu>.active>a {
        box-shadow: inset 0 4px 0 0 var(--emerald-500);
    }

    .chat-sidebar-profile {
        margin-top: 0;
        display: flex;
        align-items: center;
    }

    .chat-sidebar-profile-toggle {
        width: 32px;
        height: 32px;
    }

    .chat-sidebar-profile-dropdown {
        left: unset;
        right: 16px;
    }

    .conversation,
    .chat-content {
        padding-left: unset;
    }

    .content-sidebar {
        left: unset;
        z-index: 10;
        width: 100%;
    }

    .chat-sidebar-menu>*>a::before {
        left: 50%;
        transform: translateX(-50%);
        bottom: 100%;
        top: unset;
    }

    .chat-sidebar-menu>*>a:hover::before {
        bottom: calc(100% + 8px);
        left: 50%;
    }

    .chat-content {
        position: relative;
        height: calc(100% - 48px);
    }

    .conversation.active {
        position: relative;
        z-index: 20;
    }

    .conversation-back {
        display: flex;
    }

    .conversation-default.active {
        display: none;
        padding: 16px;
    }
}

.content-messages-list li {
    cursor: pointer;
    padding: 10px 0;
  }
  
  .content-messages-list li.active-chat {
    background-color: #7f28f820; 
  }