.custom-wrapper {
  margin: auto;
}

.stepper-wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
  margin-top: auto;
}

.stepper-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  text-align: center;
}

.stepper-item::before,
.stepper-item::after {
  position: absolute;
  content: "";
  border-bottom: 1px dotted rgb(127, 40, 248);
  width: 100%;
  top: 20px;
  z-index: 2;
}

.stepper-item::before {
  left: -50%;
}

.stepper-item::after {
  left: 50%;
}

.stepper-item .step-counter {
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #ccc;
  margin-bottom: 6px;
}

.stepper-item.active {
  font-weight: bold;
}

.stepper-item.completed .step-name {
  color: rgb(127, 40, 248);
}

.stepper-item.completed .step-counter {
  background-color: rgb(127, 40, 248);
  color: white;
}

.stepper-item.completed::after {
  border-bottom: 1px dotted rgb(127, 40, 248);
}

.stepper-item:first-child::before,
.stepper-item:last-child::after {
  content: none;
}

@media (max-width: 768px) {
  .stepper-wrapper {
    flex-direction: column;
    align-items: flex-start;
  }

  .stepper-item {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 1rem;
  }

  .stepper-item::before,
  .stepper-item::after {
    border-bottom: none;
    border-left: 1px dotted rgb(127, 40, 248);
    height: 100%;
    width: 0;
    top: auto;
    left: 20px;
  }

  .stepper-item::before {
    top: -50%;
  }

  .stepper-item::after {
    top: 50%;
  }

  .stepper-item .step-counter {
    margin-right: 1rem;
    margin-bottom: 0;
  }
}

.errorBox-wrapper {
  background-color: #F6FAF7;
  padding: 10px;
  align-items: center;
  gap: 5px;
  max-width: 520px;
  border-radius: 5px;
}

.custom-create {
  margin-top: 40px;
  max-width: 550px;
}

.create_text {
  font-size: 20px;
  color: #000000;
  margin-bottom: 15px;
  font-weight: 500;
}

.errorText {
  font-size: 12px;
  color: #5071A2;
  font-weight: 500;
}

.cus_text_li {
  font-size: 12px;
  color: #4A4A4A;
}

.custom_btns {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  gap: 40px;
}

.custom_btns button {
  width: 100%;
  max-width: 300px;
  margin-bottom: 1rem;
}

@media (min-width: 768px) {
  .custom_btns {
    flex-direction: row;
    justify-content: space-between;
  }

  .custom_btns button {
    margin-bottom: 0;
    margin-right: 1rem;
  }
}

.connect_btn {
  border: 1px solid #3C5A9A;
  align-items: center;
  background: #3C5A9A;
  font-size: 13px;
  color: white;
  gap: 2px;
  border-radius: 5px;
  width: 300px;
  padding: 8px;
  font-weight: 500;
  justify-content: center;
}

.help_btn {
  border: 1px solid #0075FF;
  color: #0075FF;
  background: none;
  border-radius: 5px;
  padding: 8px;
  width: 150px;
  font-weight: 500;
  font-size: 13px;
}

.reg_btn {
  border: 1px solid rgb(127, 40, 248);
  color: rgb(127, 40, 248);
  background: none;
  border-radius: 5px;
  padding: 8px;
  width: 150px;
  font-weight: 500;
  font-size: 13px;
}

.step-name {
  font-size: 14px !important;
  font-weight: 500 !important;
}

.step-counter {
  font-size: 14px !important;
 
}

.jcenter{
  justify-content: center;
}

.text-start{
  text-align: start;
}

.allset{
  font-size: 17px;
}

.wrap_textZ{
  align-items: center;

}

.phone_input{
  border: 1px solid #d9d9d9;
    border-radius: 5px;
    padding: 4px;
}

.note{
  font-size: 18px;
  font-weight: 600;
  margin-top: 10px;
}