.image-container {
    width: 50%;
    /* margin: 0 auto; */
}

@media (max-width: 768px) {
    .image-container {
        width: 100%;
    }
}
