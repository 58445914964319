.signup-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: Arial, sans-serif;
  height: 100vh;
  margin: 0;
  padding: 15%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}
.signup-signup-text {
  font-size: clamp(14px, 3vw, 17px);
  text-align: center;
  /* margin: 5px 0; */
  font-weight: bold;
  /* padding: 5px 0; */
}

.signup-signup-link {
  color: #7F28F8;
  text-decoration: none;
}
.signup-logo {
  width: 150px;
  margin-bottom: 20px;
}

.signup-progress-bar {
  display: flex;
  justify-content: space-between;
  margin-bottom: 60px;
}

.signup-progress-step {
  width: 14%;
  height: 7px;
  background-color: #e0e0e0;
  border-radius: 4px;
}

.signup-progress-step-active {
  background-color: #000;
}

.signup-title {
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 10px;
}

.signup-subtitle {
  font-size: 14px;
  color: #666;
  margin-bottom: 40px;
}

.signup-form-group {
  margin-bottom: 20px;
}

.signup-label {
  display: block;
  font-size: 14px;
  margin-bottom: 5px;
}

.signup-input {
  height: 50px;
  width: 100%;
  padding: 12px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 20px;
  box-sizing: border-box;
}

.signup-button {
  height: 50px;
  width: 100%;
  padding: 10px;
  font-size: 16px;
  color: white;
  background-color: #7F28F8;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
}

.signup-button-option {
  height: 80px;
  width: 100%;
  padding: 10px;
  font-size: 16px;
  color: #000000;
  background-color: #cccccc7a;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 1%;
  margin-bottom: 7%;
  font-weight: bold;
  transition: color 0.3s ease, background-color 0.3s ease;
}

.signup-button-option:hover {
  color: #ffffff;
  background-color: #7F28F8;
}

.signup-select {
  height: 50px;
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  appearance: none;
  background-color: #fff;
  margin-bottom: 20px;
}

.signup-select-wrapper {
  position: relative;
}

.signup-select-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

.signup-otp-container {
  display: flex;
  margin-bottom: 10px;
}

.signup-otp-input {
  width: 40px;
  height: 40px;
  font-size: 18px;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin: 0 7px;
}

.signup-otp-input-error {
  border: 1px solid #ff0000;
}

.signup-error-text {
  font-size: 14px;
  color: #ff0000;
  margin-bottom: 5px;
}

.signup-timer-text {
  font-size: 14px;
  color: #666;
}

.signup-resend-link {
  font-size: 14px;
  color: #0000EE;
  text-decoration: none;
  cursor: pointer;
  margin-bottom: 20%;
}

.signup-input-wrapper {
  position: relative;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.signup-icon {
  position: absolute;
  right: 10px;
  cursor: pointer;
  z-index: 1;
  height: 100%;
  display: flex;
  margin-bottom: 18px;
}

.signup-error {
  color: red;
  font-size: 14px;
  margin-top: 5px;
}

.signup-page-container {
  display: flex;
  height: 100vh;
}

.input-error {
  border-color: #ff4d4f !important;
}

.error-message {
  color: #ff4d4f;
  font-size: 12px;
  margin-top: 4px;
}

.input-error {
  border-color: #ff4d4f !important;
}

.error-message {
  color: #ff4d4f;
  font-size: 12px;
  /* margin-top: 4px; */
}

.signup-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.otp-input-signup-error {
  border-color: #ff4d4f !important;
}


.signup-shopify-button {

  height: 80px;
  width: 100%;
  padding: 10px;

  display: flex;
  align-items: center;
  justify-content: center;
  font-size: clamp(12px, 3vw, 16px);
  background-color: #cccccc7a;
  color: #000000;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  margin-top: 1%;
  margin-bottom: 7%;
}

.signup-shopify-icon {
  width: clamp(60px, 15vw, 80px);
  margin-left: 8px;
  margin-right: 5px;
}


.signup-shopify-button-2 {

  height: 80px;
  width: 100%;
  padding: 10px;

  display: flex;
  align-items: center;
  justify-content: center;
  font-size: clamp(12px, 3vw, 16px);
  background-color: #7F28F8;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  margin-top: 1%;
  margin-bottom: 7%;
}

.signup-shopify-icon-2 {
  width: clamp(5px, 15vw, 30px);
  margin-left: 8px;
  margin-right: 5px;
}


.spinner {
  border: 2px solid green;
  border-left-color: #67f68a;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: spin 1s linear infinite;
  display: inline-block;
  margin-right: 8px;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
