/* IntegrationStyles.css */

/* Smooth fade-in for logos */
.logo-animation {
    animation: fadeIn 0.8s ease-in;
    opacity: 1;
    transition: transform 0.4s ease;
}

.logo-animation:hover {
    transform: scale(1.05);
}

/* Keyframes for smooth fade-in effect */
@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: scale(0.9);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

/* Buttons for feature actions */
.feature-button {
    transition: background-color 0.3s ease, transform 0.2s ease;
    font-weight: 600;
    padding: 10px 22px;
    border-radius: 6px;
    background-color: #1c90ff;
    color: #ffffff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border: none;
}

.feature-button:hover {
    background-color: #0a7edb;
    transform: scale(1.03);
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.15);
}

/* Subtle animation for the integration intro */
.integration-intro {
    animation: slideFadeIn 1s ease;
    opacity: 1;
    margin-top: 10px;
}

@keyframes slideFadeIn {
    0% {
        opacity: 0;
        transform: translateY(15px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Steps content smooth fade effect */
.steps-content {
    animation: fadeContent 0.5s ease-in-out;
}

@keyframes fadeContent {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Modal styling for cleaner look */
.ant-modal-content {
    border-radius: 8px;
    padding: 24px;
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.15);
}

/* Responsive adjustments for mobile */
@media (max-width: 768px) {
    .feature-button {
        width: 100%;
        margin-bottom: 12px;
    }
}
