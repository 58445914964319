.pricing-section-dark {
    background-color: #fff;
    padding: 40px;
    text-align: center;
  }
  
  .billing-toggle {
    display: inline-block;
    background-color: #161616;
    padding: 10px 20px;
    border-radius: 25px;
    margin-bottom: 40px;
  }
  
  .billing-toggle span {
    padding: 5px 15px;
    cursor: pointer;
    color: white;
  }
  
  .billing-toggle span.active {
    background-color: #7030f0; /* Purple background for active */
    color: white;
    border-radius: 25px;
  }
  
  .pricing-cards-dark {
    display: flex;
    justify-content: center;
    gap: 30px;
    margin-top: 40px;
    flex-wrap: wrap; /* To make cards wrap in mobile view */
  }
  
  .pricing-card-dark {
    background-color: #1c1c1e;
    color: white;
    padding: 30px;
    border-radius: 20px;
    width: 300px;
    min-height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease;
  }
  
  .pricing-card-dark:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
  }
  
  .price {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .features {
    text-align: left;
    margin-bottom: 20px;
  }
  
  .feature-item {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-bottom: 10px;
  }
  
  .feature-item .icon {
    margin-right: 8px;
    width: 20px;
    height: 20px;
  }
  
  .action-button-dark {
    background-color: #7030f0;
    color: white;
    padding: 10px 20px;
    border-radius: 8px;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .action-button-dark:hover {
    background-color: #591dbd;
  }
  
  /* Responsive Design */
  
  /* Small devices (mobile phones) */
  @media (max-width: 600px) {
    .pricing-cards-dark {
      flex-direction: column;
      align-items: center;
      gap: 20px;
    }
  
    .pricing-card-dark {
      width: 100%; /* Take full width on small screens */
      min-height: 450px;
      padding: 20px;
    }
  
    .price {
      font-size: 28px; /* Smaller font size for price */
    }
  
    .action-button-dark {
      font-size: 16px;
      padding: 8px 16px;
    }
  }
  
  /* Medium devices (tablets) */
  @media (max-width: 1024px) {
    .pricing-cards-dark {
      gap: 20px;
    }
  
    .pricing-card-dark {
      width: 90%; /* Slightly wider cards on tablets */
    }
  
    .price {
      font-size: 30px;
    }
  
    .action-button-dark {
      font-size: 17px;
    }
  }

  .signup-logo {
    width: 150px;
    margin-bottom: 20px;
  }
  
  /* Large devices (desktops) */
  @media (min-width: 1200px) {
    .pricing-card-dark {
      width: 350px; /* Slightly larger width on larger screens */
      min-height: 550px;
    }
  
    .price {
      font-size: 36px; /* Larger font for price */
    }
  
    .action-button-dark {
      font-size: 20px;
    }
  }
  

