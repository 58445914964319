.content-message-unread {
    font-size: 12px;
    font-weight: 500;
    color: white;
    background-color: rgb(59 130 246 / 0.5);
    padding: 2px 4px;
    border-radius: 4px;
}

.sidebar-width{
    width: 20%;
}

.cus-pad-left{
    padding-left: 5px;
}


