.dashboard {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.dashboard-section {
  padding: 0px 20px;
  margin: 20px 0px;
}

.dashboard-section h1 {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 20px;
  padding: 0 10px;
}

.cards-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 0 10px;
}

.stats-card {
  flex: 1 1 calc(25% - 15px);
  max-width: calc(25% - 15px);
  /* min-width: 200px; */
  padding: 20px;
  border-radius: 10px;
  box-sizing: border-box;
}

.stats-card.light {
  background-color: #f3f4f6;
}

.stats-card.dark {
  background-color: #f3f4f6;
}

.card-content h3 {
  font-size: 16px;
  margin-bottom: 10px;
}

.card-subContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-value {
  font-size: 28px;
  font-weight: bold;
}

.card-percent p {
  margin-right: 5px;
}

@media (max-width: 1114px) {
  .stats-card {
    flex: 1 1 calc(50% - 10px);
    max-width: calc(50% - 10px);
  }
}

@media (max-width: 600px) {
  .stats-card {
    flex: 1 1 100%;
    max-width: 100%;
  }
}


.dashboard-grid {
  display: grid;
  /* grid-template-columns: repeat(2, 1fr); */
  grid-template-columns: 67% 33%;
  gap: 1rem;
  padding: 1rem;
  width: 100%;
  /* min-height: 100vh; */
}

.dashboard-grid.single {
  display: grid;
  /* grid-template-columns: repeat(2, 1fr); */
  grid-template-columns: 100%;
  gap: 1rem;
  padding: 1rem;
  width: 100%;
  /* min-height: 100vh; */
}

@media (max-width: 1024px) {
  .dashboard-grid {
    grid-template-columns: 1fr;
  }
}

.dashboard-grid-2 {
  display: grid;
  /* grid-template-columns: repeat(1, 1fr); */
  grid-template-columns: 67% 33%;
  gap: 1rem;
  padding: 1rem;
  width: 100%;
  /* min-height: 100vh; */
}



@media (max-width: 1114px) {
  .dashboard-grid-2 {
    grid-template-columns: 60% 40%;
  }
}

@media (max-width: 970px) {
  .dashboard-grid-2 {
  grid-template-columns: repeat(1, 1fr);
  }
}

.chart-wrapper {
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  box-sizing: border-box
}

.chart-container {
  width: 100%;
  height: 25rem;
  background-color: #f3f4f6;
  padding: 1rem;
  border-radius: 0.5rem;
}


.chart-title {
  font-size: 1.125rem;
  /* text-lg */
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.chart-subtitle {
  font-size: 0.875rem;
  color: #6b7280;
  margin-bottom: 1rem;
}

.chart-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.chart-select {
  background-color: transparent;
  font-size: 0.875rem;
  color: #6b7280;
}

.legend-wrapper {
  position: absolute;
  right: 0;
  top: 0;
  width: 50%;
}


.icon {
  fill: currentColor;
  width: 1rem;
  height: 1rem;
}

.chart-data {
  margin-bottom: 1.5rem;
}

.data-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.data-label {
  display: flex;
  align-items: center;
}

.color-circle {
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 50%;
  margin-right: 0.5rem;
}

.data-name {
  font-size: 0.875rem;
}

.data-value {
  font-size: 0.875rem;
  font-weight: 500;
}

.chart-body {
  height: 16rem;
}