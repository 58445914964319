.reset-input-container {
  position: relative;
    /* margin-bottom: 20px; */
    display: flex;
    align-items: center;
}

.reset-input {
  height: 50px;
  width: 100%;
  padding: 12px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 20px;
  box-sizing: border-box;
}

.validation-container {
  margin-top: 0.75rem;
  padding: 1rem;
  background-color: #855dc7 ; /*#5E4B7E Light green background */
  border-radius: 0.5rem; /* Adjusted for rounded-lg */
}

.validation-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.validation-icon {
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 9999px; /* Fully rounded */
  display: flex;
  align-items: center;
  justify-content: center;
}

.validation-icon.valid {
  /* background-color: #D8D1EA; Green */
}

.validation-icon.invalid {
  background-color: #CBC3E3; /* Gray */
}

.validation-text {
  font-size: 0.875rem; /* Small text */
  font-weight: bold;
}

.validation-text.valid {
  color: #A89ABF;
    /* Gray */
  font-weight: bold;
}

.validation-text.invalid {
  
  color: #E6E0F8;
    /* Green */
  font-weight: bold;
}
