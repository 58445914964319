.container {
    padding: 1rem;
    width: 100%;
  }
  
  .flex-container {
    display: flex;
    flex-direction: column;
    /* gap: 1rem; */
  }
  
  @media (min-width: 768px) {
    .flex-container {
      flex-direction: row;
      /* gap: 3rem; */
    }
  }
  
  .menu {
    flex: 1;
  }
  
  .content {
    flex: 3;
  }
  
  .icon_wrapper_chat{
    border: 1px solid gray;
    height: 90px;
    width: 90px;
    border-radius: 50%;
  }

  .side-border{
    border-right: 1px solid #EEF1F7;
    padding-left: 20px !important;
    padding-right: 20px !important;

  }

  .chatbot-details{
    border: 1px solid black;
    border-radius: 5px;
    padding: 10px;
  }
.chatbot_header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px 14px;
  border: 1px solid black;
  border-radius: 5px;
background-color: black;
color: #25D366;


}
/* .chatacc_left{
} */

.chatacc_right{
  float: right;
}
.whatapp_icon{
  width: 30px;
  height: 30px;
}

.icon_wrapper_chat_chatbot{
  width: 40px;
  height: 40px;
  border-radius: 50%;

}
  @media (max-width: 768px) {
    .icon_wrapper_chat {
      width: 50px !important;
      height: 50px !important;
    }
  }



  .save_data{
    background-color: #7f28f8;
    color: white;
    border-color: rgb(209 213 219);
    width: 25%;
    border-radius: 4px;
    padding: 10px;
  }


    /* styles.css */
    .verticalLine {
      border-left: 2px solid rgba(128, 40, 248, 0.281);
      height: 1200px;
      margin-left: 10px;
      margin-right: 10px;
    }
  
    @media (max-width: 768px) {
      .verticalLine {
        display: none;
      }
    }

.membership-plan-cards{
    display: flex;
      justify-content: space-evenly;
      align-items: center;
}

.pricing-card {
  border: 1px solid #cccccc42;
  padding: 20px;
  border-radius: 5px;
  /* box-shadow: 0 2px 5px rgba(77, 76, 76, 0.26); */
  box-shadow: 0 2px 5px rgba(146, 146, 146, 0.205);
  background-color: #fff;
  height: auto;
  width: 90%;
  max-width: 350px;
  margin: 0 auto;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.pricing-card:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 10px rgba(77, 76, 76, 0.4);
}

.price-font-style {
  display: inline-block;
  font-weight: 400;
  line-height: 2.25rem;
  font-size: 1.875rem;
}

.card-header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.price-font {
  font-weight: 600;
  font-size: 3rem;
  line-height: 1;
}

.card-header h3 {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}

.card-header .price {
  font-size: 24px;
  font-weight: bold;
}

.billing-options {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-top: 10px;
}

.toggle-button-container {
  margin-left: 40px;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px;
  display: inline-block;
}

.toggle-button {
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: 5px;
  color: grey;
}

.toggle-button.active {
  background-color: #7f28f8;
  color: #fff;
}

.features {
  list-style: none;
  padding: 0;
}

.features li {
  margin-bottom: 5px;
}

.upgrade-button {
  font-family: Arial, sans-serif;
  font-size: 16px;
  font-weight: bold;
  background-color: #fff;
  color: #333;
  border: 1px solid #333;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 10px;
  display: block;
  margin: 20px auto 0;
}

.upgrade-button:hover {
  background-color: #7f28f8;
  border: 1px solid #7f28f8;
  color: white;
}





.current-button {
  font-family: Arial, sans-serif;
  font-size: 16px;
  font-weight: bold;
  background-color: #fff;
  color: #333;
  border: 1px solid #333;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 10px;
  display: block;
  margin: 20px auto 0;
}

/* .current-button:hover {
  background-color: #7f28f8;
  border: 1px solid #7f28f8;
  color: white;
} */


@media (max-width: 768px) {
  .pricing-card {
    width: 95%;
    padding: 15px;
  }

  .price-font {
    font-size: 2.5rem;
  }

  .card-header h3 {
    font-size: 18px;
  }

  .features li {
    margin-bottom: 8px;
  }

  .upgrade-button {
    font-size: 14px;
    padding: 8px 16px;
  }

  .current-button {
    font-size: 14px;
    padding: 8px 16px;
  }
}

@media (max-width: 480px) {
  .pricing-card {
    width: 100%;
    padding: 10px;
  }

  .price-font {
    font-size: 2rem;
  }

  .card-header h3 {
    font-size: 16px;
  }

  .features li {
    margin-bottom: 10px;
  }

  .upgrade-button {
    font-size: 12px;
    padding: 6px 12px;
  }

  .current-button {
    font-size: 14px;
    padding: 8px 16px;
  }
}

.extra-message-credits {
  margin-top: 50px;
  border: 1px solid #cccccc42;
  padding: 10px;
  height: auto;
  width: 90%;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(146, 146, 146, 0.205);
  margin: 20px auto;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.extra-message-credits:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 10px rgba(77, 76, 76, 0.205);
}


.chatbot-credit {
  font-size: 24px;
  font-weight: bold;
  margin: 15px 15px;
}

.extra-message-credits hr{
  background-color: #cccccc42;
  border: none;
  height: 2px;
}

hr {
  border: 1px solid #cccccc42;
  width: 100%;
  margin: 10px 0;
}

.price {
  font-weight: bold;
  color: grey;
  font-size: 18px;
  margin: 10px 15px;
}

.price-info {
  font-size: 16px;
  color: grey;
  margin: 10px 15px;
}

.upgrade-button {
  font-family: Arial, sans-serif;
  font-size: 16px;
  font-weight: bold;
  background-color: #fff;
  color: #333;
  border: 1px solid #333;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 10px;
  display: inline-block;
  margin-top: 15px;
  /* Margin on top */
}

.current-button {
  font-family: Arial, sans-serif;
  font-size: 16px;
  font-weight: bold;
  background-color: #fff;
  color: #333;
  border: 1px solid #333;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 10px;
  display: inline-block;
  margin-top: 15px;
  /* Margin on top */
}



@media (max-width: 768px) {
  .extra-message-credits {
    width: 95%;
    padding: 15px;
  }

  .chatbot-credit {
    font-size: 22px;
  }

  .price {
    font-size: 16px;
  }

  .price-info {
    font-size: 14px;
  }

  .upgrade-button {
    font-size: 14px;
    padding: 8px 16px;
  }
}

@media (max-width: 480px) {
  .extra-message-credits {
    width: 100%;
    padding: 10px;
  }

  .chatbot-credit {
    font-size: 20px;
  }

  .price {
    font-size: 14px;
  }

  .price-info {
    font-size: 12px;
  }

  .upgrade-button {
    font-size: 12px;
    padding: 6px 12px;
  }
  .current-button {
    font-size: 12px;
    padding: 6px 12px;
  }
}

.tick-icon{
  margin-right: 10px;
  height: 1.5vh;
}

.features div{
  display: flex;
  align-items: center;
}

.features div li{
  list-style-type: none;
  font-weight: 500;
}