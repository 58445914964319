.setup-container {
    
    margin: auto;
    text-align: center;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .steps-bar {
    margin-bottom: 30px;
  }
  
  .step-content {
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    width: 60%;
  }
  
  .input-box {
    width: 50%;
    margin-top: 10px;
  }
  
  .button-group {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    gap: 10px;
  }
  
  .color-picker {
    width: 100%;
    height: 40px;
    border: none;
    cursor: pointer;
  }
  
  .header_chat {
    background: #f0f0f0;
    height: 76px;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    justify-content: space-between;
    border: 1px solid #e1e1e5;
  }
  
  .header_chat img {
    border: 1px solid #7f2ef8;
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

  .header_chat_wrapper {
    border: 1px solid #f0f0f0;
    padding: 10px;
    width: 100%;
  }

  .messages-container {
    height: calc(77% - 0px);
    overflow-y: scroll;
    padding: 10px;
  }

  .chat-box {
    background: white;
    width: 100%;
    padding-top: 10px;
    height: 72vh;
    border-radius: 10px;
    border: 1px solid #f0f0f0;
    z-index: 999;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }