.login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: Arial, sans-serif;
  height: 100vh;
  margin: 0;
  padding: 15%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.login-logo {
  width: 150px;
  margin-bottom: 20px;
}

.login-heading {
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 10px;
}

.login-subheading {
  font-size: 14px;
  color: #666;
  margin-bottom: 20px;
}

.login-input-label {
  display: block;
  font-size: 14px;
  margin-bottom: 5px;
}

.login-input {
  height: 50px;
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.login-input.error {
  border-color: red;
}

.login-input-container {
  position: relative;
}

.login-password-toggle {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 1;
}

.login-button {
  height: 50px;
  width: 100%;
  padding: 10px;
  font-size: 16px;
  background-color: #7F28F8;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.login-signup-text {
  font-size: 17px;
  text-align: center;
  margin: 20px 0;
  font-weight: bold;
  padding: 10px 0;
}

.login-signup-link {
  color: #7F28F8;
  text-decoration: none;
}

.login-shopify-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  padding: 10px 15px;
  font-size: 14px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  margin: 0 auto;
}

.login-shopify-icon {
  width: 80px;
  margin-left: 10px;
}

.login-forgot-password {
  font-size: 12px;
  text-align: right;
  margin-bottom: 20px;
}

.error-message {
  color: red;
  font-size: 12px;
}

.login-button:disabled {
  background-color: #7f28f88a;
  cursor: not-allowed;
  opacity: 0.6;
}