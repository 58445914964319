.login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: Arial, sans-serif;
  min-height: 100vh;
  margin: 0;
  padding: 5%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.login-logo {
  width: 150px;
  max-width: 100%;
  margin-bottom: 20px;
}

.login-heading {
  font-size: clamp(24px, 5vw, 30px);
  font-weight: bold;
  margin-bottom: 10px;
  line-height: 1.2;
}

.login-subheading {
  font-size: clamp(12px, 3vw, 14px);
  color: #666;
  margin-bottom: 20px;
  line-height: 1.4;
}

.login-input-label {
  display: block;
  font-size: clamp(12px, 3vw, 14px);
  margin-bottom: 5px;
}

.login-input {
  height: 45px;
  width: 100%;
  padding: 10px;
  font-size: clamp(12px, 3vw, 14px);
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 20px;
  outline: none;
  box-sizing: border-box;
}

.login-input.error {
  border-color: red;
}

.login-button {
  height: 45px;
  width: 100%;
  padding: 10px;
  font-size: clamp(14px, 3vw, 16px);
  background-color: #7F28F8;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
}

.login-signup-text {
  font-size: clamp(14px, 3vw, 17px);
  text-align: center;
  margin: 20px 0;
  font-weight: bold;
  padding: 10px 0;
}

.login-signup-link {
  color: #7F28F8;
  text-decoration: none;
}

.login-shopify-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  padding: 8px 12px;
  font-size: clamp(12px, 3vw, 14px);
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  margin: 0 auto;
}

.login-shopify-icon {
  width: clamp(60px, 15vw, 80px);
  margin-left: 8px;
}

.error-message {
  color: red;
  font-size: clamp(10px, 2.5vw, 12px);
}

/* Mobile-specific styles */
@media screen and (max-width: 768px) {
  .login-container {
    padding: 20px;
    box-shadow: none;
  }

  .login-button {
    height: 40px;
  }

  .login-input {
    height: 40px;
  }
}

/* Small mobile devices */
@media screen and (max-width: 320px) {
  .login-container {
    padding: 15px;
  }

  .login-heading {
    font-size: 20px;
  }

  .login-subheading {
    font-size: 12px;
  }
}