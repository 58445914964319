.file-btn {
  width: 100%;
  /* max-width: 700px; */
  /* width: 700px; */
  height: 250px;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
  color: #292D32;
  background-color: #fff;
  border: 1.5px dashed #7600eb84;
  /* border: 1.5px dashed #d9d6e8; */
  background-color: #f8f2fe;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
  /* margin-top: 120px; */
}


.upload-file-card {
  background-color: #EEF1F7;
  margin-top: 10px;
  padding: 20px;
  border-radius: 10px;

}

.parent-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* Additional styles as needed */
}


.file-btn:hover {
  color: #5d4dcc;
  background-color: #f8f2fe;
}

.file-btn span {
  width: 50px;
  height: 50px;
  font-size: 30px;
  color: #5d4dcc;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  /* background-color: #f1efff; */
}

.file-card {
  /* margin-top: 120px; */
  width: 100%;
  max-width: 700px;

  height: 100px;
  display: flex;
  align-items: center;
  gap: 15px;
  color: #000;
  background-color: #EEF1F7;
  /* border: 1px solid rgba(117, 96, 255, 0.281); */
  border-radius: 6px;
  padding: 0px 15px;
  margin-top: 20px;
}

.file-info {
  flex: 1;
  display: flex;
  align-items: center;
  gap: 15px;

}

.file-info h6 {
  flex: 1;
  font-size: 13px;
  font-weight: 400;
}

.progress-bg {
  width: 97%;
  height: 5px;
  background-color: rgba(0, 0, 0, 0.076);
  border-radius: 8px;
  margin-top: 8px;
  margin-left: 10px;
}

.progress {
  width: 0%;
  height: 5px;
  background-color: #5d4dcc;
  border-radius: 8px;
  transition: width 0.5s ease;
}

.icon {
  font-size: 30px;
  color: #7460ff;
}

.close-icon {
  font-size: 18px;
  cursor: pointer;
}

.file-info button,
.check-circle {
  width: 33px;
  height: 36px;
  display: flex;
  /* align-items: center; */
  justify-content: center;
  font-size: 13px;
  color: #463a99;
  background-color: #f1efff;
  border: none;
  border-radius: 30px;
}

.upload-btn {
  width: 700px;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  background-color: #7460ff;
  border: none;
  border-radius: 8px;
  padding: 10px;
  margin-top: 15px;
  cursor: pointer;
}

.up_para {
  color: #A9ACB4;
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 0;
  margin-top: -8px;
}


.w-site {
  width: 100%;
}

.w-site-input {
  width: 60%;
}

.textClr-btn {
  color: #fff;
  font-weight: 500;
}


.datespan {
  color: #A9ACB4;
  font-size: 14px;
  font-weight: 500;

}

.view_text {
  font-size: 14px;
  font-weight: 500;
  color: #292D32;
}

.file_name_text {
  font-size: 18px !important;
  font-weight: 500 !important;
  color: #292D32;
}


.crossIcon {
  position: relative;
  top: -8px;
}

.deleteIcon {
  color: #CF0101 !important;
  font-size: 18px !important;
  margin-left: 20px;
}

.file_name {
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 600;
  color: #292D32;
}

.filetest {
  display: flex;
  justify-content: space-between !important;
  align-items: center;
}

.leftPdf-img {
  width: 10%;
}

.rightPdf-img {
  width: 90%;
}


.download-icon{
  font-size: 20px;
  color: #5d4dcc;
}


/* .scroll-files{
  overflow: scroll;
  height: 120px;
}


.scroll-files::-webkit-scrollbar {
  display: none;
} */
/* @media (max-width: 408px) {
  .mob-res-gap {
   gap: 3.4rem;
  }
} */


.customTabs {
  /* width: 100%;
  max-width: 700px; */
  margin: 15px;
  justify-content: space-between;
  border-radius: 10px;
  border: 1px solid gainsboro;
}

.leftTab,
.rightTab {
  width: 50%;
  padding: 10px;
  /* border-radius: 20px; */
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 2px;
  transition: background-color 0.3s ease; /* Add transition for smooth color change */
}

.leftTab.active,
.rightTab.active {
  background-color: #7f28f8;
  color: #ffffff;
}

.leftTab {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.rightTab{
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

/* Add more styles as needed */

.viewIcon{
  font-size: 18px;
  color: #292D32;
}

.textweb{
  text-align: center;
  /* padding: 10px; */
  font-size: 15px;
}

.spin_gap{
  display: flex;
  align-items: center;
  gap: 5px;
}