/* Workflow.css */
.workflow-card-wrapper {
    max-width: 250px;
    margin-bottom: 20px;
  }
  
  .workflow-card {
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
  
  .menu {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  
  .custom-node-input {
    width: 100%;
    margin-top: 8px;
  }
  
  .workflow-content {
    position: relative;
    width: 100%;
    height: calc(100vh - 64px);
    overflow: hidden;
  }
  
  .workflow-card-wrapper {
    cursor: move;
    margin-bottom: 20px;
  }
  
  .node-config-sidebar {
    position: fixed;
    right: 0;
    top: 0;
    width: 400px;
    height: 100%;
    background-color: #fff;
    padding: 20px;
    box-shadow: -2px 0 8px rgba(0, 0, 0, 0.1);
    border-left: 1px solid #ddd;
  }
  
  .workflow-card-wrapper {
    cursor: move;
    margin-bottom: 20px;
  }
  
  .workflow-card {
    width: 250px;
  }
  
  