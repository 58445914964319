* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.onboarding-slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  opacity: 0;
  transition: opacity 1s ease-in-out;
  z-index: 0;
}

.onboarding-slide.active {
  opacity: 1;
  z-index: 1;
}

