.customDropdown{
  margin: 10px 15px;
  border-radius: 40px;
  padding: 5px 10px !important;
    height: 20px;
    width: 150px;
    font-weight: bold;
}

.language-dropdown-relative {
  position: relative;
  padding: 5px 10px !important;
  height: 10px;
  width: 150px;
  font-weight: bold;
  margin: 5px 15px;
}

.language-dropdown-w-32 {
  width: 8rem;
}

.language-dropdown-button {
  width: 100%;
  height: 40px;
  padding-left: 1rem;
  padding-right: 1rem;
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.875rem;
  cursor: pointer;
  outline: none;
}

.language-dropdown-button:focus {
  outline: 2px solid #7F28F8;
  outline-offset: 2px;
}

.language-dropdown-icon {
  width: 1rem;
  height: 1rem;
  transition: transform 200ms cubic-bezier(0.4, 0, 0.2, 1);
}

.language-dropdown-rotate-180 {
  transform: rotate(180deg);
}

.language-dropdown-menu {
  position: absolute;
  width: 100%;
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  z-index: 50;
}

.language-dropdown-menu-item {
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  text-align: left;
  font-size: 0.875rem;
  cursor: pointer;
  transition: background-color 150ms ease;
}

.language-dropdown-menu-item:hover {
  border-radius: 1rem;
  width: 100%;
  background-color: #f1f1f1;
}

.language-dropdown-selected {
  background-color: #f9fafb;
}