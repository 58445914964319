* {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

::-webkit-scrollbar {
  display: none;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(255, 255, 255);

}

.white-text-clr {
  color: #ffffff;
  font-weight: 500;
}


a {
  margin: 0;
  padding: 0;
  text-decoration: none !important;
  color: inherit !important;
  /* Set your custom color */
  /* Add more properties as needed to reset other styles */
}

.cus-text-white {
  color: white !important;
  font-weight: 500;
}

.custom-table {
  /* width: 70%; */
  border: 1px solid #f0f0f0;
  /* padding: 20px 10px; */
  border-radius: 15px;
  box-shadow: 0 8px 24px -4px rgba(0, 0, 0, .1);

}


table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  /* border: 1px solid #dddddd; */
  text-align: left;
  padding: 12px;
  white-space: normal;
}

.bR {

  border-radius: 8px 8px 0 0;
  color: rgba(0, 0, 0, 0.88);
  font-weight: 600;
  text-align: start;
  background: #EEF1F7;
  border-bottom: 1px solid #f0f0f0;
  transition: background 0.2s ease;

  /* transition: background 0.2s, border-color 0.2s; */
}

.justify-content-between {
  justify-content: space-between;
}

.bl-question {
  border-radius: 8px 0 0 0;
  background: #EEF1F7;
  font-size: 15px;

}

.br-action {
  border-radius: 0 8px 0 0;
  background: #EEF1F7;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.88);
  font-size: 15px;

}

.f-r {
  text-align: end;
  border-top-right-radius: 8px !important;
  /* background: #fafafa; */

}


.td-wid {
  max-width: 300px;
  white-space: normal;
  overflow: hidden;
  font-size: 14px;
  text-overflow: ellipsis;

}

tr:hover {
  background-color: #f5f5f5;
  border-top-right-radius: 8px;

}

/* tr{
  border-top-right-radius:8px; 

} */


.custom-table-wrapper tr {
  border-bottom: 1px solid #f0f0f0;
  border-top-right-radius: 8px;
  background: transparent;


}

.bg-table {
  background: #fafafa;
  border-top-right-radius: 8px !important;
}

.cus-hg {
  width: 90px;
  height: 90px;
  background: transparent !important;
}

.bg-clr-btn {
  background-color: #ffffff !important;
  border: 2px solid #7F28F8 !important;
  color: #7F28F8 !important;
  font-weight: 500;
}

.bg-clr-btn:hover {
  background-color: #7F28F8 !important;
  color: #ffffff !important;

}

.textClrcode {
  width: 100%;
}

.cus-in-wid {
  width: 100px;
}


.cus-wrapper-bb {
  border-bottom: 1px solid #EEF1F7;
  padding-bottom: 20px;
}

.menu_hover .ant-menu-item:hover {
  background-color: #ffffffa4;
  color: #ffffff;

}

.menu_hover .ant-menu-item-selected {
  background-color: #ffffff;
  color: #7F28F8;
}

.save_btn {
  background-color: #7F28F8;
  padding: 5px 10px;
  color: #ffffff;
  font-weight: 500;
  border-radius: 6px;
  max-width: 144px;
  width: 100%;
}

.switcher_clr {
  background-color: #c6c6c6;
}

.fw-500 {
  font-weight: 500;
}



.widwrap {
  width: 60%;
}

.logoutbtn {
  color: #ffffff;
  background-color: #334155;
  padding: 20px;
  padding-left: 24px;
  position: absolute;
  width: 100%;
  bottom: 0;
  font-size: 16px;
  font-weight: 500;
}

.hidden {
  display: none;
}


/* Setting's heading */

.Upload_wrapper {
  border-radius: 15px;
  border: 1px solid #E8E8E8;
  padding-top: 20px;
  width: 98%;
  /* max-width: 700px; */

}

.side-pad {
  padding-left: 20px;
  padding-right: 20px;
}

.bg-sethead {
  color: #7600EB;
  font-weight: 700;
  font-size: 25px;
  margin-bottom: 2px;

}

.inner-sethead {
  font-weight: 300;
  color: #000000;
  font-size: 19px;

}

.w-set {
  width: 720px;
}

.upload_text {
  font-size: 17px;
  color: #292D32;
  font-weight: 500;
}

.upload-para {
  font-size: 14px;
  color: #A9ACB4;
  font-weight: 500;

}

.custom-ant-card-body {
  padding: 0;
}


.fileUpload_wrapper {
  padding: 20px;
}


.cus-inp-wid {
  width: 100%;
}

.inp-wid {
  width: 70%;
}

.padd-15 {
  padding: 15px;
}

.cusMargin-15 {
  margin: 15px;
}

/* .fixed-container {
  position: fixed;
  bottom: 2%;
  right: 45px;
  z-index: 999;
} */

/* Main container for the chat section */

.wrapper_res{
  max-width: 1000%;

}
.mainChat {
  border: 1px solid #f0f0f0;
  padding: 0 !important;
  width: 100%;
  border-radius: 4px;
  position: relative;
  height: 100vh;
  overflow: hidden;
}

/* Header styling */
.header_chat_wrapper {
  border: 1px solid #f0f0f0;
  padding: 10px;
  width: 100%;
}

/* Wrapper for both left and right sections */
.bothWrapper {
  width: 100%;
  justify-content: space-between;
  padding: 10px;
  height: calc(100% - 60px);
  /* Adjust based on the height of the header */
  display: flex;
  position: relative;
}

/* Left section which should scroll */
.halfLeft {
  width: 50%;
  overflow-y: auto;
  padding-right: 15px;
}

/* Right section which should be fixed */
.halfRight {
  width: 35%;
  /* position: absolute; */
  right: 10px;
  top: 10px;
  /* Adjust based on the height of the header */
  bottom: 10px;
  padding-right: 15px;
  padding-left: 15px;
  /* border: 1px solid red; */
  overflow-y: auto;
}

/* Custom margin classes */
.mt-cus {
  margin-top: 40px;
}

.mt-cus-right {
  margin-top: 20px;
}

/* Input width customization */
.inMsg_input_wid {
  width: auto !important;
}

/* Note message styling */
.msg_note {
  margin-top: 10px;
  font-size: 12px;
}

/* Color input customization */
.inMsg_input_wid_color {
  width: 30px !important;
  height: 30px;
}


/* padding-0 */
.pd-0 {
  padding: 0 !important;
}

/* Note paragraph styling */
.note_para {
  color: #696969;
}

/* Chat header styling */
.chatIn_head {
  font-size: 25px;
  font-weight: 600;
  margin-bottom: 0;
}

/* Flexbox alignment for input wrapper */
.inMsg-wrapper {
  align-items: center;
  justify-content: space-between;
}

/* Input label styling */
.inMsg_head {
  font-size: 15px;
  font-weight: 500;
}

/* Reset button styling */
.resetBtn {
  background: rgb(201, 201, 201);
  padding: 4px 10px;
  border-radius: 3px;
  color: black;
  font-weight: 600;
}

/* General input styling */
.inMsg_input {
  border: 1px solid #f0f0f0;
  width: 100%;
  margin-top: 10px;
  padding-top: 7px;
  padding-bottom: 7px;
  border-radius: 5px;
  padding-left: 7px;
}

.forPad{
  padding-bottom: 7px;
  border-radius: 5px;
  padding-left: 7px;
  padding-top: 7px;
  padding-right: 28px;

}

.inMsg_input_sugMsg {
  border: 1px solid #f0f0f0;
  width: 100%;
  padding: 7px;
  border-radius: 5px;
}

.cus_inMsg_gap {
  gap: 10px;
}

/* Chatbox styling */
.chat-box {
  background: white;
  padding-top: 10px;
  height: 72vh;
  border-radius: 10px;
  border: 1px solid #f0f0f0;
  width: 100%;
  z-index: 999;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* Chat header inside chatbox */
.header_chat {
  background: #f0f0f0;
  height: 76px;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  justify-content: space-between;
  border: 1px solid #e1e1e5;
}

.header_chat img {
  border: 1px solid #7f2ef8;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.whatsapp-icon {
  /* background-image: url("https://upload.wikimedia.org/wikipedia/commons/5/5e/WhatsApp_icon.png"); */
  background-size: cover;
  width: 25px;
  height: 25px;
  border-radius: 50%;
}

.messages-container {
  height: calc(77% - 0px);
  overflow-y: scroll;
  padding: 10px;
}

.message {
  margin-bottom: 10px;
}

.business-message {
  padding: 6px;
  padding-top: 12px;
  padding-bottom: 12px;
  border-radius: 5px;
  background: #f0f0f0;
  color: black;
  width: 100%;
  word-wrap: break-word;
}

.user-message {
  padding: 8px;
  border-radius: 5px;
  background: #f0f0f0;
  color: black;
  width: 100%;
}

.input-container {
  position: relative;
  display: flex;
  width: 100%;
}

.input-container input {
  width: 100%;
  border: 1px solid #f0f0f0;
  background: #f3f3f3;
  padding: 10px 40px 10px 10px;
  color: #444;
  border-radius: 15px;
  font-size: 16px;
  line-height: 2;
}

.input-container button {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  cursor: pointer;
}

.input-container button svg {
  fill: #000;
}

.conversation-item-text-new {
  border: 1px solid #f0f0f0;
  border-radius: 5px;
  background: #f0f0f0;
  color: black;
  width: 90%;
  margin-left: auto;
  margin-right: 0;
  margin-bottom: 10px;
  padding: 10px;
}

.conversation-item-text-new p {
  margin: 0;
  cursor: pointer;
  word-wrap: break-word;
}

.fixed_icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  padding: 0;
  /* border: none; */
  background-color: #f0f0f0;
  border: 1px solid black;
  border-radius: 50%;
  cursor: pointer;
  float: right;
}

.chatBot_img {
  width: 30px;
  height: 30px;
  border-radius: 7px;
  /* Makes the image circular */
  /* No filter, so the image retains its own color */
}

.addMsg {
  background-color: #7f28f8;
  color: white;
  border-color: rgb(209 213 219);
  width: 25%;
  border-radius: 4px;
  padding: 10px;
}

.save_data {
  background-color: #7f28f8;
  color: white;
  border-color: rgb(209 213 219);
  width: 25%;
  border-radius: 4px;
}

.suggWrapper_chat {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* Responsive styles */
@media (max-width: 768px) {
  .bothWrapper {
    flex-direction: column;
  }

  .halfLeft,
  .halfRight {
    width: 100%;
    position: static;
    height: auto;
  }

  .halfRight {
    border: none;
    padding: 10px;
  }
}

.conversation-item-text-new {
  padding: 10px;
  margin-bottom: 5px;
  border-radius: 5px;
}

.icon_sugg {
  position: relative;
}

.icon_sugg_pos {
  position: absolute;
  top: 13px;
  right: 2px;
  padding: 6px;

  background: #ffffff;
}

.icon_sugg_op {
  position: absolute;
  top: 5px;
  right: 3px;
  padding: 5px;

  background: #ffffff;
}

.initialMsg_text {
  margin-bottom: 0;
}

.sug-wid {
  width: 70%;
}

.forWid {
  width: 100%;
}

.cus-border-sug {
  border-radius: 5px;
}


.ant-spin-dot-item {
  background-color: #7600EB !important;
}

.newWrapper {
  display: flex;
}

.chatWapper {
  width: 400px;
}

.newWrapper_main {
  width: 75%;
  /* align-items: end;
  padding-right: 30px; */
}

/* .newWrapper_lower {
  width: 25%;
  align-items: end;
  padding-right: 30px;
} */

.newWrapper_lower {
    width: 25%;
  border-radius: 7px;
  border: 1px solid #E8E8E8;
  height: 200px;
  position: relative;
}

.createBot {
  /* width: 90%; */
  background-color: #7600EB;
  padding: 10px 15px;
  border-radius: 7px;
  color: white;
  font-size: 14px;
  font-weight: 500;
  position: absolute;
  bottom: 6px;
  right: 0;
  left: 0;
  margin: 0 10px;

}

.cusCreate {
  padding: 10px;
  text-align: center;
}

.head_cuscreate {
  font-weight: 700;
  font-size: 15px;
  color: #292D32;
}
.para_cuscreate{
  font-weight: 600;
  font-size: 13px;
  color: #8d8d8d;
}
.span_cuscreate{
  font-weight: 700;
  color: #292D32;
  font-size: 13px;
}
.span_cuscreate_clr{
  font-weight: 600;
  color: #8d8d8d;
  font-size: 13px;
}
  .spin_bg .ant-spin-dot-item{
  background-color: white !important;
}

.customLayout-pad{
  padding: 0 30px !important;
}

.textAlign-center{
  text-align: center;
  font-size: 20px;
}
@media (max-width: 768px) { 
  .newWrapper {
    display: block;
  }
  .newWrapper_main {
    width: 100%;
  }
  .Upload_wrapper{
    width: 100%;

  }
}

@media (max-width: 991.98px) { 
  .newWrapper {
    display: block;
  }
  .newWrapper_main {
    width: 100%;
 
  }
  .Upload_wrapper{
    width: 100%;

  }
  .newWrapper_lower{
    width: 100%;
    margin-top: 20px;
  }
  .createBot{
    width: 100%;
  }
}

.btndata{
  gap: 5px;
  align-items: center;
  display: flex;
 justify-content: center;
}

.help_main{
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 800px;
}

.wrapperPad{
  padding: 0 16px;
}

.customDropdown{
  background: #8420ff;
  border-color: #d9d9d9;
  color: rgb(255 255 255 / 88%);
}
.customDropdown:hover{
  background: #8420ff !important;
  border-color: #d9d9d9 ;
  color: rgb(255 255 255 / 88%) !important;
}

.customDropdown:active{
  background: #8420ff !important;
  border-color: #d9d9d9 ;
  color: rgb(255 255 255 / 88%) !important;
}

.arConverstion{
  position: absolute;
  left: 0;
  width: calc(100% - 256px);
  height: 100%;
}
.arPad{
  /* padding-right: 258px; */
  position: absolute;
  top: 112px;
  right: 31%;
  /* padding-left: 0 !important; */
}


.logo-animation {
  transition: transform 0.3s ease-in-out;
}

.logo-animation:hover {
  transform: scale(1.1);
}

.invitation-button {
  transition: background-color 0.3s ease;
}

.invitation-button:hover {
  background-color: #52c41a;
}

.paid-status-container {
  background-color: #f0f4f7;
  border: 1px solid #4caf50;  /* Green border to indicate success or completion */
  border-radius: 8px;
  padding: 10px 20px;
  text-align: center;
  display: inline-block;
  max-width: fit-content;
}

.paid-text {
  color: #4caf50;  /* Green text to match the border */
  font-size: 1.25rem;
}

.ticket-system-container {
  padding: 24px;
  background: #f9f9f9;
}

.ticket-system-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.ticket-list {
  margin-top: 16px;
}

.ticket-card {
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.ticket-card-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ticket-title {
  font-size: 1.2em;
  font-weight: bold;
  margin: 0;
}

.ticket-description {
  margin-top: 8px;
  color: #555;
  font-size: 0.9em;
}

.ticket-image img {
  max-width: 100%;
  border-radius: 8px;
}

.help-section {
  margin-top: 40px;
  padding: 20px;
  background: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.help-section .title {
  font-size: 1.8em;
  font-weight: bold;
  color: #6c5ce7;
  margin-bottom: 20px;
}

.integration-item {
  margin-bottom: 20px;
}

.integration-item iframe {
  border-radius: 10px;
}

