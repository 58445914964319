.container {
  width: 100%;
  border-bottom: 1px solid #e5e7eb;
  background-color: #F6F6F6;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
}

.header-title {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-weight: 600;
  font-size: 25px;
  color: #1f2937;
}

.header-info {
  color: #9ca3af;
  font-size: 18px;
}

.test-bot-button {
  padding: 0.25rem 0.75rem;
  background-color: #7F28F8;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  color: #fff;
  height: 50px;
  width: 100px;
  font-weight: bold;
}

.upload-train-button {
  padding: 0.25rem 0.75rem;
  margin: 0 1.5rem ;
  background-color: #7F28F8;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  color: #fff;
  height: 50px;
  width: 150px;
  font-weight: bold;
}

upload-train-button:disabled{
  background-color: #7f28f88a;
    cursor: not-allowed;
    opacity: 0.6;
}

.tab-navigation {
  display: flex;
  gap: 1.5rem;
  padding: 0 1.5rem;
}

.tab-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 0;
  border-bottom-width: 2px;
  transition: color 0.2s;
}

.tab-button.active {
  border-color: #7F28F8;
  color: #7F28F8;
}

.tab-button.inactive {
  border-color: transparent;
  color: #6b7280;
}

.tab-button.inactive:hover {
  color: #374151;
}

.links-content {
  margin: 1.5rem;
  width:70%
}

.link-section {
  background-color: white;
  border-radius: 6px;
  border: 2px solid #eee;
  padding: 1.5rem;
}

.link-section-title {

  font-size: 1.125rem;
  font-weight: bold;
  color: #1f2937;
}

.link-section-description {
  color: #4b5563;
  font-size: 0.875rem;
  margin-bottom: 2rem;
}

.link-input {
  width: 100%;
  padding: 0.5rem 1rem;
  border: 1px solid #d1d5db;
  border-radius: 0.5rem;
  transition: border-color 0.2s;
}

.link-input-2 {
  width: 100%;
  padding: 0.5rem 1rem;
  border: 1px solid #d1d5db;
  border-radius: 0.5rem;
  transition: border-color 0.2s;
  height: 50px;
}

.link-input:focus {
  outline: none;
  ring: 2px solid #7F28F8;
}

.add-more-button {
  color: #7F28F8;
  font-weight: bold;
  transition: color 0.2s;
}

/* .add-more-button:hover {
  color: #374151;
} */

.submit-button {
  padding: 0.25rem 0.75rem;
    background-color: #7F28F8;
    border-radius: 0.375rem;
    font-size: 0.875rem;
    color: #fff;
    height: 50px;
    width: 100px;
    font-weight: bold;
}

.submit-button:hover {
  background-color: #7F28F8;
}



.train-container {
  width: 30%;
  background-color: white;
  border-radius: 0.5rem;
  padding: 1.5rem;
  border: 1px solid #e5e7eb;
  height: 180px;
  margin: 1.5rem;
}

.train-title {
  font-size: 1.125rem;
  font-weight: bold;
  color: #111827;
  margin-bottom: 1rem;
}

.train-link-info {
  font-size: 0.875rem;
  color: #4b5563;
  margin-bottom: 1rem;
}

.train-sub-title {
  font-size: 0.875rem;
  font-weight: bold;
  color: #7F28F8;
  margin-bottom: 0.5rem;
}

.train-limit-info {
  font-size: 0.75rem;
  color: #4b5563;
  margin-top: 0.25rem;
}



.links-table {
  background-color: white;
  border-radius: 0.5rem;
  border: 1px solid #e5e7eb;
  overflow: hidden;
  padding: 1.5rem;
  margin:1.5rem
}
.links-table-body thead {
  position: sticky;
  top: 0;
  z-index: 1;
}
.links-table-header {
  padding: 1rem;
  border-bottom: 1px solid #e5e7eb;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.links-table-search {
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: space-between;
}

.links-table-input {
  padding-left: 2.5rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border: 1px solid #d1d5db;
  border-radius: 0.5rem;
  outline: none;
  transition: ring 0.2s;
}

.links-table-input:focus {
  ring: 2px solid #7F28F8;
}

.links-table-select {
  appearance: none;
  background: white;
  border: 1px solid #d1d5db;
  border-radius: 0.5rem;
  padding: 0.5rem 2rem;
}

.links-table-body {
  width: 100%;
}

.links-table-row {
  border-top: 1px solid #e5e7eb;
}

.links-table-cell {
  padding: 1rem;
}

.links-table-cell input[type="checkbox"] {
  appearance: none;
  /* Remove default appearance */
  -webkit-appearance: none;
  /* For Safari */
  width: 16px;
  height: 16px;
  border: 2px solid #cbd5e1;
  /* Light gray border */
  border-radius: 4px;
  background-color: white;
  cursor: pointer;
  position: relative;
  outline: none;
  margin: 0;
}

.links-table-cell input[type="checkbox"]::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px;
  height: 10px;
  background-color: #7F28F8;
  /* Custom green color */
  transform: scale(0) translate(-50%, -50%);
  transition: transform 0.2s ease;
  border-radius: 2px;
}

.links-table-cell input[type="checkbox"]:checked::before {
  transform: scale(1) translate(-50%, -50%);
}

.links-table-cell input[type="checkbox"]:checked {
  border-color: #7F28F8;
  /* Change border to green when checked */
}

.links-table-status {
  /* display: flex;
  align-items: center;
  gap: 0.5rem; */
}

.links-table-pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-top: 1px solid #e5e7eb;
}

.links-table-pagination-button {
  padding: 0.5rem 0.75rem;
  border: 1px solid #d1d5db;
  border-radius: 0.25rem;
  font-size: 0.875rem;
}

.links-table-pagination-active {
  background-color: #7F28F8;
  color: white;
}

.selectedlinks-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  border-radius: 0.5rem;
  border: 1px solid #e5e7eb;
  padding: 12px;
  margin: 1.5rem

}

.selectedlinks-text {
  font-size: 14px;
  font-weight: bold;
  color: #374151;
}

.selectedlinks-buttonContainer {
  display: flex;
  gap: 8px;
}

.selectedlinks-button {
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 4px;
  cursor: pointer;
}

.selectedlinks-cancelButton {
  border: 1px solid #d1d5db;
  background-color: white;
  color: #374151;
  cursor: pointer;
  font-weight: bold;
}

.selectedlinks-startButton {
  background-color: #7F28F8;
  color: #fff;
  border: none;
  cursor: pointer;
  font-weight: bold;
  padding:15px
}


.submit-button:disabled {
  background-color: #7f28f88a;
  cursor: not-allowed;
  opacity: 0.6;
}

.selectedlinks-startButton:disabled{
    background-color: #7f28f88a;
    cursor: not-allowed;
    opacity: 0.6;
  }


    .testbot-panel {
      position: fixed;
      top: 0;
      right: 0;
      height: 100%;
      width: 30rem;
      z-index: 5;
      background-color: white;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      transition: transform 300ms ease-in-out;
    }
  
    .testbot-panel--open {
      transform: translateX(0);
    }
  
    .testbot-panel--closed {
      transform: translateX(100%);
    }
  
    .testbot-panel-header {
      padding: 2rem;
    }
  
    .testbot-panel-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;
    }
  
    .testbot-panel-title {
      font-size: 1.25rem;
      font-weight: 600;
    }
  
    .testbot-panel-close-button {
      color: #6B7280;
    }
  
    .testbot-panel-close-button:hover {
      color: #374151;
    }




        .testbot-chat-container {
          padding: 20px;
          width: 22rem;
          background-color: white;
          border-radius: 0.5rem;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
          overflow: hidden;
          display: flex;
          flex-direction: column;
          height: 820px;
        }
    
        .testbot-chat-header {
          background-color: #7F28F8;
          padding: 0.75rem 1rem;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
    
        .testbot-chat-title {
          font-size: 0.875rem;
          font-weight: 600;
        }
    
        .testbot-chat-body {
          flex-grow: 1;
          padding: 1rem;
          overflow-y: auto;
        }
    
        .testbot-chat-message {
          background-color: #f7fafc;
          border-radius: 0.5rem;
          padding: 0.75rem;
          margin-bottom: 0.5rem;
        }
    
        .testbot-chat-avatar {
          width: 1.5rem;
          height: 1.5rem;
          background-color: #7F28F8;
          border-radius: 50%;
          margin-right: 0.5rem;
          display: flex;
          align-items: center;
          justify-content: center;
          color: white;
          font-size: 0.75rem;
          font-weight: bold;
        }
    
        .testbot-chat-text {
          background-color: #7F28F8;
          border-radius: 0.5rem;
          padding: 0.5rem;
          display: inline-block;
        }
    
        .testbot-chat-text-content {
          font-size: 0.75rem;
        }
    
        .testbot-chat-input-container {
          padding: 1rem;
          background-color: white;
        }
    
        .testbot-chat-input-wrapper {
          display: flex;
          align-items: center;
          background-color: #7F28F8;
          border-radius: 9999px;
          padding: 0.5rem 0.75rem;
        }
    
        .testbot-chat-input {
          flex-grow: 1;
          background: transparent;
          outline: none;
          font-size: 0.75rem;
        }
    
        .testbot-chat-send-button {
          margin-left: 0.5rem;
        }
    
        .testbot-chat-send-icon {
          width: 1rem;
          height: 1rem;
          color: #7F28F8;
        }
    
        .testbot-chat-footer {
          text-align: center;
          font-size: 0.75rem;
          color: #7F28F8;
          padding: 0.5rem 0;
          background-color: #7F28F8;
        }



.filelist-container {
  margin: 1.5rem;
  font-family: Arial, sans-serif;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.filelist-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #fff;
}

.filelist-header-title {
  margin: 0;
  font-size: 18px;
  font-weight: bold;
}

.filelist-search-container {
  position: relative;
}

.filelist-search-input {
  padding: 8px 30px 8px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.filelist-search-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #666;
}

.filelist-table {
  width: 100%;
  border-collapse: collapse;
}

.filelist-table-header {
  background-color: #f5f5f5;
  border-bottom: 1px solid #e0e0e0;
}

.filelist-table-header th {
  padding: 10px 20px;
  text-align: left;
  font-weight: normal;
  color: #666;
  font-size: 14px;
}

.filelist-table-row {
  border-bottom: 1px solid #fff;
}

.filelist-table-row td{
background-color: #fff;
}

.filelist-table-cell {
  padding: 15px 20px;
  font-size: 14px;
}

.filelist-status {
  padding: 5px 10px;
  border-radius: 12px;
  font-size: 12px;
}

.filelist-status-processing {
  background-color: #e6e6fa;
  color: #6a5acd;
}

.filelist-status-default {
  background-color: #e0f2f1;
  color: #00897b;
}

.filelist-more-option {
  padding: 15px 20px;
  position: relative;
}


.options-menu {
  position: absolute;
  top: 30px;
  right: 0;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  width: 150px;
}

.option-item {
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.option-item:hover {
  background-color: #f0f0f0;
}

.link-delete-icon{
  padding: 8px;
}

.link-delete-icon:hover{

border-radius: 4px;
  background-color: red;

}



.tooltip {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
  color: rgb(158, 34, 34);
  padding: 5px;
  border-radius: 4px;
  top: 100%;
  /* Adjust as needed */
  left: 50%;
  /* Center it */
  transform: translateX(-50%);
  white-space: nowrap;
  z-index: 1000;
  transition: opacity 0.2s;
}