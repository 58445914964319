/* General Styles */
.custom-table {
  padding: 16px;
}

/* 

/* Button Styles */
.buttonChat,
.buttonAdmin,
.buttonAddChoice {
  margin: 8px;
}

/* Table Styles */


/* Specific Styles */
.chatbox-sug button:active {
  color: #7f28f8 !important;
}

.sugg-btn [type=button]:not(:disabled) {
  color: #7f28f8;
}

.dlt-clr {
  color: #ff4d4f !important;
}

.tabs {
  display: flex;
  border-bottom: 2px solid #7f28f8;
}

.tab {
  padding: 10px 20px;
  cursor: pointer;
  border: none;
  background-color: transparent;
  color: #555;
  font-size: 16px;
  padding-bottom: 5px;
  border-bottom: 1px solid #CBD0DC;
  transition: color 0.3s;
}

.tab.active {
  color: #7f28f8;
  border-bottom: 2px solid #7f28f8;
}

.tab.disabled {
  cursor: not-allowed;
  color: #999;
}

.tab-content {
  padding: 20px;
}

.questionHead-wrapper {
  display: flex;
  gap: 10px;
  align-items: center;
}

.questionHead {
  margin-bottom: 5px;
  color: #292D32;
  font-size: 18px;
  font-weight: 600;
}

.questionPara {
  margin-bottom: 0;
  color: #A9ACB4;
  font-size: 14px;
  font-weight: 500;
}

.buttonChat,
.buttonAdmin,
.buttonAddChoice {
  background-color: transparent;
  border: 1px solid #555;
  padding: 10px 15px;
  color: #292D32;
  font-weight: 500;
  border-radius: 4px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.buttonChat.active,
.buttonAdmin.active,
.buttonAddChoice.active {
  background-color: #7f28f8;
  color: #ffffff;
}

.tablewrapper {
  padding: 10px 20px;
}

.cus-wid-input {
  width: 75%;
}

.cus-wid-btn {
  width: 25%;
}

.mainHeading {
  font-size: 17px;
  font-weight: 500;
}

.td-wid ul {
  list-style-type: disc;
  padding-left: 0;
}

.td-wid li {
  margin-bottom: 5px;
}

.qs-head {
  font-size: 17px;
}

.loader-div {
  position: absolute;
  top: 50%;
  left: 56%;
  transform: translate(-50%, -50%);
}

.loader-div-filelist {
  position: absolute;
  top: 80%;
  left: 56%;
  transform: translate(-50%, -50%);
}

/* Mobile Styles */
@media (max-width: 768px) {
  .custom-table {
    padding: 8px;
  }

  .section-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-direction: column;
    /* align-items: flex-start; */
  }



  .buttonChat,
  .buttonAdmin,
  .buttonAddChoice {
    width: 100%;
    margin-bottom: 8px;
  }

  .tablewrapper {
    width: 100%;
  }

  th,
  td {
    font-size: 14px;
  }

  .mainHeading {
    font-size: 16px;
  }

  .cus-wid-input,
  .cus-wid-btn {
    width: 100%;
  }

  .section-wrapper {
    gap: 20px;
  }

  .bottom-section-wrapper {
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
  }

  .tab-content {
    padding: 10px;
  }
}

/* Adjustments for very small screens */
@media (max-width: 480px) {

  th,
  td {
    font-size: 12px;
    padding: 6px;
  }

  .mainHeading {
    font-size: 14px;
  }

  .questionHead {
    font-size: 16px;
  }

  .questionPara {
    font-size: 12px;
  }

  .buttonChat,
  .buttonAdmin,
  .buttonAddChoice {
    font-size: 14px;
    padding: 8px 10px;
  }

  .qs-head {
    font-size: 14px;
  }
}


.wrap_table {
  border: 1px solid #f0f0f0;
  border-radius: 10px;
}

.tablewrapper {
  position: relative;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  /* Adjust height as needed */
}

.table-content {
  display: none;
}

.wrap_table .table-content {
  display: block;
}

.wrap_table .loader-container {
  height: 100px;
}

.custom-table-wrapper {
  border: none;
}

.pad-left-wrap {
  padding-left: 12px !important;
}

.ant-spin-dot-item {
  background-color: #7f28f8 !important;
}

.line-with-text {
  text-align: center;
  margin: 10px 0;
}

.line-with-text hr {
  border: none;
  height: 1px;
  background-color: #c2c2c2;
  margin: 0 auto;
  width: 100%;
}

.line-text {
  display: inline-block;
  padding: 0 20px;
  position: relative;
  top: -10px;
  background-color: #fff;
  font-size: 16px;
  color: #616161;
}

.deleteAll_wrapper {
  padding: 20px;
}


/* .delete_all {
  background-color: #d7d7d7;
  border-radius: 3px;
  text-align: center;
  padding: 9px !important;
  display: flex;
  justify-content: center;
  color: #ff4d4f;
} */

/* .delete_all {
  background-color: #c2c2c2;
  border-radius: 3px;
  text-align: center;
  padding: 9px !important;
} */


.delete_allicon {
  color: #000;
}

.texticon{
  align-items: center;
  gap: 10px;
  justify-content: end;
}

.dlt-clr-text{
color: #ff4d4f;
font-size: 14px;
}

.heightWrapperList {
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 4px;
  margin-top: 20px;
}

.list_wrapper {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 6px;
}

.list-item-div {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 100%;
  gap: 10px;
  /* overflow-x: scroll; */
  word-break: break-word;
}
.list-item-div {
  -ms-overflow-style: none; 
  scrollbar-width: none;  
  &::-webkit-scrollbar {
      display: none;
  }
}
.list-item-div:hover {
  background-color: #f2f2f2;
}

.delete-icon {
  position: absolute;
  right: 0;
  color: red;
  font-size: 16px;
  cursor: pointer;
}


.cnt-alg {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 90%;
}

.dlt_btn{
  background-color: rgb(127, 40, 248);
  color: white;
  border-color: rgb(127, 40, 248);
}

.wrapLinks{
  justify-content: space-between;
  padding: 13px;
  align-items: center;
}

/* .ant-btn-default .rem-icon{
  background-color: transparent !important;
  border: none !important;
  border-color: transparent !important;
} */